const pathnames = {
    pageHome: "/",
    pageAccountSignIn: "/account/sign-in",
    pageAccountCreate: "/account/create",
    pageAccountMySwitch: "/account/my-account",
    pageAccountProfile: "/account/profile",
    pageAccountRecoveryAndroid: "/account/backup-recovery/android",
    pageAccountRecoveryIOS: "/account/backup-recovery/ios",
    pageActivatePlan: "/activate-plan",
    pageActivatePlanReview: "/activate-plan/review-plan",
    pageActivatePlanSuccess: "/activate-plan/success",
    pageSwitchSelectDevice: "/switch/select-device",
    pageSwitchDeliveryDetails: "/switch/delivery-details",
    pageSwitchPaymentDetails: "/switch/payment-details",
    pageSwitchSuccess: "/switch/success",
    pageCookiePolicy: "/cookie-policy",
    pageAbout: "/about",
};

export default pathnames;
