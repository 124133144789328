import React, { memo, useEffect, useCallback } from "react";
import { Modal } from "reactstrap";
import { IoCloseOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";

import { resetAlert } from "redux/slices/alert-slice";
import Button from "components/shared/app-button";

const AppAlertModal = () => {
    const dispatch = useDispatch();
    const { alert } = useSelector((state) => state);

    const onHandleDismissAlert = useCallback(() => {
        dispatch(resetAlert());
    }, [dispatch]);

    useEffect(() => {
        onHandleDismissAlert();
    }, [onHandleDismissAlert]);

    return (
        <Modal className="app-alert-modal" isOpen={alert.active} toggle={onHandleDismissAlert} centered>
            <div className="content">
                <p className="content__text">{alert.message}</p>
                <IoCloseOutline className="content__close-button" onClick={onHandleDismissAlert} />
            </div>
            <Button label={alert.buttonLabel} type="button" onClick={onHandleDismissAlert} />
        </Modal>
    );
};

export default memo(AppAlertModal);
