import React, { memo, useEffect, useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Modal } from "reactstrap";
import { useTranslation } from "react-i18next";

import Button from "components/shared/app-button";
import Input from "components/form/input";

const initialValues = {
    otp: "",
};

const AppOtpModal = ({ headline, isOpen, toggle, onSubmit }) => {
    const { t } = useTranslation("otpModal");
    const modalHeadline = headline ? headline : t("headline");
    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                otp: Yup.number().required(t("form.field.required")).typeError(t("form.field.required")),
            }),
        [t]
    );

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            onSubmit(values, callback);
        },
    });

    const callback = (error) => {
        formik.setSubmitting(false);

        if (error) {
            formik.setFieldError("otp", error);
        }
    };

    useEffect(() => {
        const onHandleClearErrorMessage = () => {
            if (!isOpen && (formik.values.otp || formik.errors.otp)) {
                formik.resetForm(initialValues);
            }
        };

        onHandleClearErrorMessage();
    }, [isOpen, formik]);

    const onHandleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value.trim();
        formik.setFieldValue(name, value);
    };

    return (
        <Modal className="app-otp-modal" isOpen={isOpen} toggle={() => toggle(false)} centered>
            <p className="app-otp-modal__text">{modalHeadline}</p>

            <form className="app-otp-modal__form" autoComplete="off" onSubmit={formik.handleSubmit}>
                <Input name="otp" label={t("form.field.label")} value={formik.values.otp} touched={formik.touched.otp} error={formik.errors.otp} onChange={onHandleChange} />
                <Button label={t("form.submitButton")} type="submit" disabled={formik.isSubmitting} />
            </form>
        </Modal>
    );
};

export default memo(AppOtpModal);
