import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    initateSwap: {},
    devices: [],
    summary: {},
};

export const switches = createSlice({
    name: "switches",
    initialState,
    reducers: {
        updateDevices: (state, action) => {
            return {
                ...state,
                devices: action.payload,
            };
        },
        updateInitiateSwap: (state, action) => {
            return {
                ...state,
                initateSwap: action.payload,
            };
        },
        updateSummary: (state, action) => {
            return {
                ...state,
                summary: action.payload,
            };
        },
        resetSwitches: () => initialState,
    },
});

export const { updateDevices, updateSummary, updateInitiateSwap, resetSwitches } = switches.actions;

export default switches.reducer;
