import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    termsAndConditionsUrl: "#",
    privacyPolicyUrl: "#",
};

export const fileManagementSlice = createSlice({
    name: "fileManagement",
    initialState,
    reducers: {
        updateFileManagement: (state, action) => {
            return {
                ...state,
                termsAndConditionsUrl: action.payload.termsAndConditionsUrl,
                privacyPolicyUrl: action.payload.personalDataProtectionPolicyAndPracticesUrl,
            };
        },
        resetFileManagement: () => initialState,
    },
});

export const { updateFileManagement, resetFileManagement } = fileManagementSlice.actions;

export default fileManagementSlice.reducer;
