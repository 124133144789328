import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    priceTier: [],
    redeemsSubscriptions: {
        redeems: [
            {
                code: "",
                imei: "",
                device: "",
            },
        ],
    },
    devicesDropdown: [],
};

export const subscriptions = createSlice({
    name: "subscriptions",
    initialState,
    reducers: {
        updatePriceTier: (state, action) => {
            return {
                ...state,
                priceTier: action.payload,
            };
        },
        updateSubscriptions: (state, action) => {
            return {
                ...state,
                redeemsSubscriptions: {
                    redeems: action.payload.redeems,
                },
            };
        },
        updateDevicesDropdown: (state, action) => {
            return {
                ...state,
                devicesDropdown: action.payload,
            };
        },
        resetSubscriptions: () => initialState,
    },
});

export const { updateSubscriptions, updateDevicesDropdown, updatePriceTier, resetSubscriptions } = subscriptions.actions;

export default subscriptions.reducer;
