import itServerErrors from "locales/it/serverErrors.json";
import enServerErrors from "locales/en/serverErrors.json";
import enFrontend from "locales/en/frontend.json";
import itFrontend from "locales/it/frontend.json";

const en = {
    ...enServerErrors,
    ...enFrontend,
};

const it = {
    ...itServerErrors,
    ...itFrontend,
};

const locales = { en, it };

export default locales;
