import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    active: false,
};

export const sessionExpiredModalSlice = createSlice({
    name: "sessionExpiredModal",
    initialState,
    reducers: {
        showModal: (state) => {
            return {
                ...state,
                active: true,
            };
        },
        resetModal: (state) => {
            return {
                ...state,
                active: false,
            };
        },
    },
});

export const { showModal, resetModal } = sessionExpiredModalSlice.actions;

export default sessionExpiredModalSlice.reducer;
