import React, { Fragment, memo, useState, useCallback } from "react";
import { IoCheckmarkSharp } from "react-icons/io5";

const OptionsCheckbox = ({ label, touched, error, componentLabel, options = [], defaultValue = null, ...props }) => {
    const [checked, setChecked] = useState(defaultValue);
    const invalid = touched && error;

    const onToggleChecked = useCallback((value) => {
        setChecked(value);
        props?.onChange?.(value);
    }, [props]);

    return (
        <div className="app-options-checkbox">
            
            {label ? <p className="app-options-checkbox__question">{label}</p> : null}

            <div className="app-options-checkbox__option-list">
                {options.map((opt, index) => {
                    const optKey = `opt-${index}`;
                    const checkedItem = opt.value === checked;

                    return (
                        <Fragment key={optKey}>
                            <div className="app-options-checkbox__wrapper">
                                <button type="button" className="app-options-checkbox__checker" onClick={() => onToggleChecked(opt.value)}>
                                    {checkedItem ? <IoCheckmarkSharp /> : null}
                                </button>
                                {label ? <p className="app-options-checkbox__label">{opt.label}</p> : null}
                            </div>
                        </Fragment>
                    );
                })}
            </div>

            {invalid ? <p className="app-options-checkbox__text app-options-checkbox__text--error">{error}</p> : null}
        </div>
    );
};

export default memo(OptionsCheckbox);
