import PageHome from "pages/home";
import PageAccountCreate from "pages/account/account-create";
import PageAccountProfile from "pages/account/account-profile";
import PageAccountRecoveryAndroid from "pages/account/account-backup-recovery-android";
import PageAccountRecoveryIOS from "pages/account/account-backup-recovery-ios";
import PageAccountSignIn from "pages/account/account-sign-in";
import PageAccountMySwitch from "pages/account/account-my-switch";
import PageActivatePlan from "pages/activate-plan/activate-plan";
import PageActivatePlanSuccess from "pages/activate-plan/activate-plan-success";
import PageActivatePlanReviewDevice from "pages/activate-plan/activate-plan-review";
import PageSwitchSelectDevice from "pages/switch/switch-select-device";
import PageSwitchDeliveryDetails from "pages/switch/switch-delivery-details";
import PageSwitchPaymentDetails from "pages/switch/switch-payment-details";
import PageSwitchSuccess from "pages/switch/switch-success";
import PageAbout from "pages/about";
import PageCookiePolicy from "pages/cookie-policy";
import pathnames from "routes/pathnames";

const routes = [
    {
        path: pathnames.pageAccountSignIn,
        component: PageAccountSignIn,
        title: "Sign In",
        authenticate: false,
    },
    {
        path: pathnames.pageAccountCreate,
        component: PageAccountCreate,
        title: "Account Create",
        authenticate: false,
    },
    {
        path: pathnames.pageAccountRecoveryIOS,
        component: PageAccountRecoveryIOS,
        title: "Recovery IOS",
        authenticate: true,
    },
    {
        path: pathnames.pageAccountRecoveryAndroid,
        component: PageAccountRecoveryAndroid,
        title: "Recovery Android",
        authenticate: true,
    },
    {
        path: pathnames.pageAccountMySwitch,
        component: PageAccountMySwitch,
        title: "My Switch",
        authenticate: true,
    },
    {
        path: pathnames.pageAccountProfile,
        component: PageAccountProfile,
        title: "Profile",
        authenticate: true,
    },
    {
        path: pathnames.pageActivatePlanSuccess,
        component: PageActivatePlanSuccess,
        title: "Activate Plan Sucess",
        authenticate: true,
    },
    {
        path: pathnames.pageActivatePlanReview,
        component: PageActivatePlanReviewDevice,
        title: "Activate Plan Review",
        authenticate: true,
    },
    {
        path: pathnames.pageActivatePlan,
        component: PageActivatePlan,
        title: "Activate Plan",
        authenticate: true,
    },
    {
        path: pathnames.pageSwitchSelectDevice,
        component: PageSwitchSelectDevice,
        title: "Switch Select Device",
        authenticate: true,
    },
    {
        path: pathnames.pageSwitchDeliveryDetails,
        component: PageSwitchDeliveryDetails,
        title: "Switch Delivery Details",
        authenticate: true,
    },
    {
        path: pathnames.pageSwitchPaymentDetails,
        component: PageSwitchPaymentDetails,
        title: "Switch Delivery Details",
        authenticate: true,
    },
    {
        path: pathnames.pageSwitchSuccess,
        component: PageSwitchSuccess,
        title: "Switch Success",
        authenticate: true,
    },
    {
        path: pathnames.pageAbout,
        component: PageAbout,
        title: "About",
        authenticate: false,
    },
    {
        path: pathnames.pageCookiePolicy,
        component: PageCookiePolicy,
        title: "Cookie policy",
        authenticate: false,
    },
    {
        path: pathnames.pageHome,
        component: PageHome,
        title: "Home",
        authenticate: false,
    },
];

export default routes;
