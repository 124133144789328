import React, { useEffect, useCallback } from "react";
import { compose } from "redux";
import { useTranslation } from "react-i18next";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import routes from "routes/routes";
import history from "routes/history";
import withAuthenticate from "contexts/with-authenticate";
import withTermsAndConditions from "contexts/with-terms-and-conditions";
import AppSessionExpiredModal from "components/shared/app-session-expired-modal";
import AppAlertModal from "components/shared/app-alert-modal";

const App = () => {
    const { i18n } = useTranslation();
    const defaultRoute = `/${i18n.language}/`;

    useEffect(() => {
        localStorage.setItem("@i18n_persist_lang", i18n.language);
    }, [i18n.language]);

    const getLanguages = useCallback(() => {
        return Object.keys(i18n.services.resourceStore.data);
    }, [i18n]);

    history.listen(() => {
        window.scrollTo(0, 0);
    });

    return (
        <Router history={history}>
            <AppSessionExpiredModal />
            <AppAlertModal />
            <Switch>
                {routes.map((route) => {
                    const prefixLanguage = "/:language";
                    const prefixUrl = prefixLanguage + route.path;
                    const EnhanceRoute = compose(withAuthenticate)(route.component, route.authenticate);
                    const supportedLanguages = getLanguages();
                    return (
                        <Route
                            key={prefixUrl}
                            path={prefixUrl}
                            exact={true}
                            render={(props) => {
                                const paramsLanguage = props.match.params.language;
                                if (supportedLanguages.includes(paramsLanguage)) {
                                    return <EnhanceRoute {...props} />;
                                } else {
                                    return <Redirect to={defaultRoute} />;
                                }
                            }}
                        />
                    );
                })}
                <Redirect to={defaultRoute} />
            </Switch>
        </Router>
    );
};

const EnhanceApp = compose(withTermsAndConditions)(App);

export default EnhanceApp;
