import React, { memo, useState, useCallback } from "react";
import { IoCheckmarkSharp } from "react-icons/io5";

const Checkbox = ({ label, touched, error, componentLabel, disabled, defaultValue = false, ...props }) => {
    const [checked, setChecked] = useState(defaultValue);
    const invalid = touched && error;

    const onToggleChecked = useCallback(() => {
        setChecked(!checked);
        props?.onChange?.(!checked);
    }, [checked, props]);

    return (
        <div className="app-checkbox">
            <div className="app-checkbox__wrapper">
                <button type="button" disabled={disabled} className={getInputClassNames({ checked, disabled })} onClick={onToggleChecked}>
                    {checked ? <IoCheckmarkSharp className="app-checkbox__checked-icon" /> : null}
                </button>

                {componentLabel ? componentLabel : null}

                {label ? <p className="app-checkbox__label">{label}</p> : null}
            </div>

            {invalid ? <p className="app-checkbox__text app-checkbox__text--error">{error}</p> : null}
        </div>
    );
};

const getInputClassNames = ({ checked, disabled }) => {
    const classNames = ["app-checkbox__checker"];

    if (checked) classNames.push("app-checkbox__checker--active");

    if (disabled) classNames.push("app-checkbox__checker--disabled");

    return classNames.join(" ");
};

export default memo(Checkbox);
