import React, { useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import pathnames from "routes/pathnames";
import AppPrefixLink from "components/shared/app-prefix-link";

const AppFooter = () => {
    const { fileManagement } = useSelector((state) => state);
    const { t } = useTranslation("footer");
    const footerNav = useMemo(
        () => [
            { label: t("termsAndConditions"), to: fileManagement.termsAndConditionsUrl, cdnUrl: true },
            { label: t("privacyPolicy"), to: fileManagement.privacyPolicyUrl, cdnUrl: true },
            { label: t("cookiePolicy"), to: pathnames.pageCookiePolicy, cdnUrl: false },
        ],
        [t, fileManagement]
    );

    return (
        <footer className="app-footer">

            <div className="app-footer__container container">
                <div className="app-footer__branding">
                    <AppPrefixLink className="app-footer__branding-link" to={pathnames.pageHome} activeClassName="active">
                        <span className="app-footer__branding-logo"></span>
                    </AppPrefixLink>
                </div>

                <p className="app-footer__section">{t("contactUs")}</p>
                <ul className="app-footer__nav-list">
                    <li className="app-footer__nav-item">
                        {t("phone")}
                        <a className="app-footer__nav-link" href={t("common:customerService.fields.phone.value")}>
                            {t("common:customerService.fields.phone.label")}
                        </a>
                    </li>
                    <li className="app-footer__nav-item">|</li>
                    <li className="app-footer__nav-item">
                        {t("email")}
                        <a className="app-footer__nav-link" href={t("common:customerService.fields.email.value")}>
                            {t("common:customerService.fields.email.label")}
                        </a>
                    </li>
                </ul>
            </div>
            
            <div className="app-footer__divider" />

            <div className="app-footer__container container">
                <ul className="app-footer__nav-list">
                    {footerNav.map((nav, index) => {
                        const navKey = `nav-item-${index}`;

                        if (nav.cdnUrl) {
                            return (
                                <li className="app-footer__nav-item" key={navKey}>
                                    <a className="app-footer__nav-link" href={nav.to} target="_blank" rel="noopener noreferrer">
                                        {nav.label}
                                    </a>
                                </li>
                            );
                        } else {
                            return (
                                <li className="app-footer__nav-item" key={navKey}>
                                    <AppPrefixLink className="app-footer__nav-link" to={nav.to} activeClassName="active">
                                        {nav.label}
                                    </AppPrefixLink>
                                </li>
                            );
                        }
                    })}
                </ul>

                <small className="app-footer__copyright">{t("companyName")}</small>
            </div>

        </footer>
    );
};

export default memo(AppFooter);
