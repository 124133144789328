import React, { useState, useMemo, useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import cloneDeep from "lodash/cloneDeep";
import { useTranslation, Trans } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import pathnames from "routes/pathnames";
import { getPrefixUrl } from "common/utilities";
import Checkbox from "components/form/checkbox";
import AppButton from "components/shared/app-button";
import AppLayout from "components/shared/app-layout";
import AppPagination from "components/shared/app-pagination";
import AppDeliveryAddressModal from "components/pages/switch/app-delivery-address-modal";
import pickupIcon from "assets/images/switch/pick-up.svg";
import switchAndReplaceIcon from "assets/images/switch/switch-and-replace.svg";
import { updateDevices } from "redux/slices/switch-slice";

const initialValues = {
    terms01: "",
};

const pageLimit = 6;
const pageNeighbours = 2;

const PageSwitchDeliveryDetails = () => {
    const { t } = useTranslation("switches");
    const dispatch = useDispatch();
    const history = useHistory();
    const { devices, summary } = useSelector((state) => state.switches);
    const invalidSwitchesDevice = devices.length === 0;
    const editSubscriptionId = useRef();
    const originalShippingDevices = useRef(devices);
    const pageOneShippingDevices = originalShippingDevices.current.slice(0, pageLimit);
    const selectedEditShippingDevice = originalShippingDevices.current.filter((device) => device.subscriptionId === editSubscriptionId?.current)[0];
    const [shippingDevices, setShippingDevices] = useState(pageOneShippingDevices);
    const [editAddressModal, setEditAddressModal] = useState(false);

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                terms01: Yup.boolean().required(t("deliveryDetails.terms.form.field.required")).oneOf([true], t("deliveryDetails.terms.form.field.required")),
            }),
        [t]
    );

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: () => {
            dispatch(updateDevices(originalShippingDevices.current));
            history.push(getPrefixUrl(pathnames.pageSwitchPaymentDetails));
        },
    });

    const onHandleUpdateData = (newDevices) => {
        setShippingDevices(newDevices);
    };

    const onHandleToggleEditAddressModal = (subscriptionId) => {
        editSubscriptionId.current = subscriptionId;
        setEditAddressModal(!editAddressModal);
    };

    const onHandleMapShippingContact = (array, contact) => {
        const mappedShippingDevices = array.map((item) => {
            const newSwitchAddress = Object.assign({}, item, { switchContact: contact });
            return item.subscriptionId === editSubscriptionId.current ? newSwitchAddress : item;
        });

        return mappedShippingDevices;
    };

    const onHandleAddressModalonSubmit = (values) => {
        let cloneShippingDevices = cloneDeep(shippingDevices);
        originalShippingDevices.current = onHandleMapShippingContact(originalShippingDevices.current, values);
        cloneShippingDevices = onHandleMapShippingContact(cloneShippingDevices, values);
        setShippingDevices(cloneShippingDevices);
        setEditAddressModal(false);
    };

    if (invalidSwitchesDevice) return <Redirect to={getPrefixUrl(pathnames.pageAccountMySwitch)} />;

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-switch-delivery-details">
                <div className="switch-delivery-details">
                    <div className="switch-delivery-details__container container">
                        <div className="switch-delivery-details__row">
                            <div className="switch-delivery-details__col switch-delivery-details__col--shipping">
                                <p className="switch-delivery-details__headline switch-delivery-details__headline switch-delivery-details__headline--deco">{t("deliveryDetails.headline")}</p>

                                <div className="shipping-info">
                                    <div className="shipping-info__header">
                                        <img src={pickupIcon} alt="" />
                                        <p className="switch-delivery-details__text shipping-info__headline">{t("deliveryDetails.shippingInfo.headline")}</p>
                                    </div>

                                    <ul className="shipping-info__device-list">
                                        {shippingDevices.map((device, index) => {
                                            const key = `devices-${index}`;
                                            const deviceImage = `${process.env.REACT_APP_CDN_IMAGES_ARTICLES_URL}/${device.selectedSwitchDevice.deviceSku}-front.png`;
                                            const switchContact = device.switchContact;
                                            const companyName = switchContact.companyName;
                                            const recipientName = companyName ? companyName : `${device.switchContact.lastName} ${device.switchContact.firstName}`;

                                            return (
                                                <li key={key} className="shipping-info__device-item">
                                                    <div className="shipping-info__device">
                                                        <div className="shipping-info__device-image" style={{ backgroundImage: `url(${deviceImage})` }} />
                                                        <p className="switch-delivery-details__text shipping-info__name">{device.selectedSwitchDevice.deviceDescription}</p>
                                                    </div>

                                                    <div className="shipping-info__address">
                                                        <p className="switch-delivery-details__text shipping-info__label">{t("deliveryDetails.shippingInfo.address.label")}</p>
                                                        <p className="switch-delivery-details__text shipping-info__company-name">{recipientName}</p>
                                                        <p className="switch-delivery-details__text shipping-info__address-name">
                                                            {switchContact.street}, {switchContact.number}, <br/>
                                                            {switchContact.zipCode}, {switchContact.city}, <br/>
                                                            {switchContact.provinceCode}
                                                        </p>
                                                    </div>

                                                    <div className="shipping-info__edit-button">
                                                        <button type="button" onClick={() => onHandleToggleEditAddressModal(device.subscriptionId)}>
                                                            {t("deliveryDetails.shippingInfo.address.editButton")}
                                                        </button>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <AppPagination data={originalShippingDevices.current} pageLimit={pageLimit} totalRecords={originalShippingDevices.current.length} pageNeighbours={pageNeighbours} onHandleClick={onHandleUpdateData} />
                                </div>
                            </div>

                            <div className="switch-delivery-details__col switch-delivery-details__col--plan">
                                <p className="switch-delivery-details__headline">{t("deliveryDetails.subHeadline")}</p>

                                <div className="plan-details">
                                    <div className="plan-details__header">
                                        <p className="switch-delivery-details__text plan-details__headline">{t("deliveryDetails.plans.headline")}</p>
                                        <img src={switchAndReplaceIcon} alt="" />
                                    </div>
                                    <ul className="plan-details__lists">
                                        {summary.summaries?.map((plan, index) => {
                                            const planKey = `plan-${index}`;
                                            return (
                                                <li className="plan-details__item" key={planKey}>
                                                    <p className="switch-delivery-details__text plan-details__price">
                                                        {plan.planCodeCount} x {plan.planCode}
                                                        <span>
                                                            {plan.subTotal.amount} {plan.subTotal.currencyCode}
                                                        </span>
                                                    </p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <p className="switch-delivery-details__text plan-details__total">
                                        {t("deliveryDetails.plans.total")}{" "}
                                        <span>
                                            {summary.total.amount} {summary.total.currencyCode}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="switch-delivery-details__row">
                            <div className="switch-delivery-details__col">
                                <p className="switch-delivery-details__headline switch-delivery-details__headline switch-delivery-details__headline--deco">{t("deliveryDetails.terms.headline")}</p>
                                <div className="switch-delivery-details__terms">
                                    <Trans i18nKey="switches:deliveryDetails.terms.lists">
                                        [description]
                                        <ul>
                                            {devices.map((item, index) => {
                                                const key = `original-device-${index}`;
                                                return <li key={key}>{`${item.name} (${item.imei})`}</li>;
                                            })}
                                        </ul>
                                    </Trans>
                                </div>
                                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                    <Checkbox touched={formik.touched.terms01} error={formik.errors.terms01} onChange={(boolean) => formik.setFieldValue("terms01", boolean)} label={t("deliveryDetails.terms.form.field.label")} />

                                    <div className="switch-delivery-details__button-wrapper">
                                        <AppButton label={t("deliveryDetails.terms.form.submitButton")} type="submit" disabled={formik.isSubmitting} isLoading={formik.isSubmitting} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AppDeliveryAddressModal defaultValues={selectedEditShippingDevice?.switchContact} isOpen={editAddressModal} toggle={onHandleToggleEditAddressModal} onSubmit={onHandleAddressModalonSubmit} />
        </AppLayout>
    );
};

export default PageSwitchDeliveryDetails;
