import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import CONSTANSTS from "common/constansts";
import locales from "locales";

const i18n = i18next.createInstance();
const debug = CONSTANSTS.IS_DEVELOPMENT_MODE;

const getDefaultLanguage = () => {
    let supportedLanguage = null;
    const localesLanguages = Object.keys(locales);
    const paramsLanguage = window.location.pathname.split("/")[1];
    const localStorageLanguage = localStorage.getItem("@i18n_persist_lang");

    if (localesLanguages.includes(paramsLanguage)) {
        supportedLanguage = paramsLanguage;
    } else if (localesLanguages.includes(localStorageLanguage)) {
        supportedLanguage = localStorageLanguage;
    } else {
        supportedLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;
    }

    return supportedLanguage;
};

i18n.use(initReactI18next).init({
    compatibilityJSON: "v2",
    debug: debug,
    resources: {
        ...locales,
    },
    preload: true,
    lng: getDefaultLanguage(),
    fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE,
    react: {
        useSuspense: false,
        transSupportBasicHtmlNodes: true,
        defaultTransParent: "span",
        transKeepBasicHtmlNodesFor: ["br", "u", "b", "ul", "ol", "li"],
    },
});

export default i18n;
