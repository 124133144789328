import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import api from "services/api";
import useIsMount from "hooks/use-is-mount";
import { updateFileManagement } from "redux/slices/file-management-slice";

const withTermsAndConditions = (WrappedComponent) => ({ ...props }) => {
    const dispatch = useDispatch();
    const isMount = useIsMount();

    const getTermsAndConditionsUrl = useCallback(async () => {
        try {
            const websiteCode = process.env.REACT_APP_WEBSITE_CODE;
            const countryCode = process.env.REACT_APP_COUNTRY_CODE;
            const response = await api.get.termsAndConditionsLinks(countryCode, websiteCode);
            dispatch(updateFileManagement(response.data));
            return response;
        } catch (error) {
            console.error(error);
        }
    }, [dispatch]);

    useEffect(() => {
        if (isMount) {
            getTermsAndConditionsUrl();
        }
    }, [isMount, getTermsAndConditionsUrl]);

    return <WrappedComponent {...props} getTermsAndConditionsUrl={getTermsAndConditionsUrl} />;
};
export default withTermsAndConditions;
