import axios from "services/axios";
import CONSTANSTS from "common/constansts";

const api = {
    get: {
        profile: () => axios.get(CONSTANSTS.ENDPOINT_PATH.PROFILE),
        redeemSubscriptions: () => axios.get(CONSTANSTS.ENDPOINT_PATH.REDEEM_SUBSCRIPTIONS),
        partnerCode: (countryCode) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.PARTNER_CODE}/${countryCode}`),
        priceTier: (params) => axios.get(CONSTANSTS.ENDPOINT_PATH.PRICE_TIER, params),
        plans: () => axios.get(CONSTANSTS.ENDPOINT_PATH.PLANS),
        eligibleDevice: (params) => axios.get(CONSTANSTS.ENDPOINT_PATH.ELIGIBLE_DEVICE, params),
        searchArticle: (params) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.SEARCH_ARTICLE}${params}`),
        termsAndConditionsLinks: (countryCode, websiteCode) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.TERMS_AND_CONDITIONS_LINKS}?countryCode=${countryCode}&websiteCode=${websiteCode}`),
        allEligibleDevices: (locale) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.ALL_ELIGIBLE_DEVICES}?languageCode=${locale}&orderBy=planCode&sort=asc`),
        paymentMethod: () => axios.get(CONSTANSTS.ENDPOINT_PATH.PAYMENT_METHOD),
        availableBanks: (locale) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.AVAILABLE_BANKS}?languageCode=${locale}`),
        paymentStatus: (bulkSwapId) => axios.get(`${CONSTANSTS.ENDPOINT_PATH.SWITCH_PAYMENT_STATUS}${bulkSwapId}`),
        captchaEnabled: () => axios.get(CONSTANSTS.ENDPOINT_PATH.CAPTCHA_ENABLED),
    },
    post: {
        signIn: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.SIGN_IN, payload),
        signInWithEmail: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.SIGN_IN_WITH_EMAIL, payload),
        validateSignInOtp: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.VALIDATE_OTP, payload),
        createAccount: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_ACCOUNT, payload),
        updateProfile: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_PROFILE, payload),
        createSubscriptions: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_SUBSCRIPTIONS, payload),
        deviceSelection: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.SWITCH_DEVICES, payload),
        switchSummary: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.SWITCH_SUMMARY, payload),
        initiateSwitch: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.INITIATE_SWITCH, payload),
    },
};

export default api;
