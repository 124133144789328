import React, { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";

import pathnames from "routes/pathnames";
import { getPrefixUrl } from "common/utilities";

const getSideNavClassNames = (active) => {
    const classNames = ["app-side-nav__lists"];

    if (active) {
        classNames.push("app-side-nav__lists--active");
    }

    return classNames.join(" ");
};

const AppSideNav = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation("sideNav");
    const { profile } = useSelector((state) => state.auth);
    const [mobileNavAccordionAcitve, setMobileNavAccordionActive] = useState(false);
    const initialName = profile ? profile.contact.lastName.split("")[0] + profile.contact.firstName.split("")[0] : "";
    const profileName = profile ? profile.contact.lastName + " " + profile.contact.firstName : "";
    const sideNavList = useMemo(
        () => [
            {
                section: t("lists.0.section"),
                label: t("lists.0.label"),
                to: pathnames.pageAccountMySwitch,
                childrens: [pathnames.pageSwitchSelectDevice],
            },
            {
                label: t("lists.1.label"),
                to: pathnames.pageAccountProfile,
            },
            {
                label: t("lists.2.label"),
                to: pathnames.pageAccountRecoveryAndroid,
            },
            {
                label: t("lists.3.label"),
                to: pathnames.pageAccountRecoveryIOS,
            },
            {
                label: t("lists.4.label"),
                to: pathnames.pageHome,
            },
        ],
        [t]
    );
    const currentActiveTab = useMemo(() => {
        const currentActiveItem = sideNavList.filter((nav) => {
            return getPrefixUrl(nav.to) === location.pathname || nav?.childrens?.findIndex((child) => getPrefixUrl(child) === location.pathname) > -1;
        });

        return currentActiveItem?.[0]?.label;
    }, [sideNavList, location]);

    const onToggleMobileNavAccordionActive = useCallback(() => {
        setMobileNavAccordionActive((active) => !active);
    }, []);

    const onNavigateSideNav = useCallback(
        (to) => {
            const logoutPathname = getPrefixUrl(pathnames.pageHome);
            const logoutAction = { type: "logout" };

            switch (to) {
                case logoutPathname:
                    localStorage.clear();
                    dispatch(logoutAction);
                    history.push(to);
                    break;
                default:
                    history.push(to);
                    break;
            }
        },
        [history, dispatch]
    );

    return (
        <div className="app-side-nav">
            <div className="app-side-nav__wrapper">
                <button className="app-side-nav__toggle" onClick={() => onToggleMobileNavAccordionActive()}>
                    {currentActiveTab}
                    {mobileNavAccordionAcitve ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
                </button>

                <ul className={getSideNavClassNames(mobileNavAccordionAcitve)}>
                    <li className="app-side-nav__item app-side-nav__item--profile">
                        <div className="app-side-nav__name-initial">{initialName}</div>
                        <p className="app-side-nav__text app-side-nav__text--bold">{profileName}</p>
                    </li>

                    {sideNavList.map((nav, i) => {
                        const sideNavKey = `side-nav-${nav.section}-${i}`;
                        const path = getPrefixUrl(nav.to);
                        const childActive = nav?.childrens?.findIndex((child) => getPrefixUrl(child) === location.pathname) > -1;
                        const active = location.pathname === path || childActive;

                        const linkClassNames = active ? "app-side-nav__text app-side-nav__text--bold" : "app-side-nav__text";
                        return (
                            <li className="app-side-nav__item" key={sideNavKey}>
                                {nav.section ? <div className="app-side-nav__category">{nav.section}</div> : null}
                                <p className={linkClassNames} onClick={() => onNavigateSideNav(path)}>
                                    {nav.label}
                                </p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default AppSideNav;
