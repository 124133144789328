import { createSlice } from "@reduxjs/toolkit";
import i18n from "i18n";

const initialState = {
    active: false,
    message: "",
    buttonLabel: "",
};

export const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        updateAlert: (state, action) => {
            const defaultButtonLabel = i18n.t("alertModal:closeButton");
            const buttonLabel = action.payload.buttonLabel ? action.payload.buttonLabel : defaultButtonLabel;

            return {
                ...state,
                active: true,
                buttonLabel,
                message: action.payload.message,
            };
        },
        resetAlert: (state) => {
            return {
                ...state,
                active: false,
            };
        },
    },
});

export const { updateAlert, resetAlert } = alertSlice.actions;

export default alertSlice.reducer;
