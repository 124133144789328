import React, { Fragment, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation, Trans } from "react-i18next";

import api from "services/api";
import pathnames from "routes/pathnames";
import { updateAlert } from "redux/slices/alert-slice";
import { onHandleRequestError, getPrefixUrl, trimStrings } from "common/utilities";
import Input from "components/form/input";
import Checkbox from "components/form/checkbox";
import OptionsCheckbox from "components/form/options-checkbox";
import AppLayout from "components/shared/app-layout";
import AppButton from "components/shared/app-button";
import AppPrefixLink from "components/shared/app-prefix-link";

const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    name: "",
    vatNumber: "",
    streetName: "",
    streetNumber: "",
    zipcode: "",
    city: "",
    provinceCode: "",
    partnerCode: "bolttech",
    terms: false,
    optIn: null,
};

const countryCode = process.env.REACT_APP_COUNTRY_CODE;

const PageAccountCreate = () => {
    const { t, i18n } = useTranslation("account");
    const { fileManagement } = useSelector((state) => state);
    const [partnerCodeList, setPartnerCodeList] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                firstName: Yup.string().required(t("create.form.fields.0.required")),
                lastName: Yup.string().required(t("create.form.fields.1.required")),
                phoneNumber: Yup.string(t("create.form.fields.2.required")).required(t("create.form.fields.2.required")),
                email: Yup.string().email(t("create.form.fields.3.required")).required(t("create.form.fields.3.required")),
                name: Yup.string().required(t("create.form.fields.4.required")),
                vatNumber: Yup.string().required(t("create.form.fields.5.required")).min(11, t("create.form.fields.5.minLength")),
                streetName: Yup.string().required(t("create.form.fields.6.required")),
                streetNumber: Yup.number().required(t("create.form.fields.7.required")).typeError(t("create.form.fields.7.required")),
                zipcode: Yup.number().required(t("create.form.fields.8.required")).typeError(t("create.form.fields.8.required")),
                city: Yup.string().required(t("create.form.fields.9.required")),
                provinceCode: Yup.string().required(t("create.form.fields.10.required")),
                partnerCode: Yup.string().required(t("create.form.fields.11.required")),
                terms: Yup.boolean().required(t("create.form.fields.12.required")).oneOf([true], t("create.form.fields.12.required")),
                optIn: Yup.boolean().required(t("create.form.fields.13.required")).typeError(t("create.form.fields.13.required")),
            }),
        [t]
    );
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            onHandleCreateAccount(values);
        },
    });
    const inputs = useMemo(
        () => [
            {
                section: t("create.form.fields.0.section"),
                sectionDescription: t("create.form.fields.0.sectionDescription"),
                type: "text",
                name: "firstName",
                label: t("create.form.fields.0.label"),
            },
            {
                type: "text",
                name: "lastName",
                label: t("create.form.fields.1.label"),
            },
            {
                type: "tel",
                name: "phoneNumber",
                label: t("create.form.fields.2.label"),
            },
            {
                type: "email",
                name: "email",
                label: t("create.form.fields.3.label"),
            },
            {
                section: t("create.form.fields.4.section"),
                type: "text",
                name: "name",
                label: t("create.form.fields.4.label"),
            },
            {
                type: "text",
                name: "vatNumber",
                maxLength: 11,
                label: t("create.form.fields.5.label"),
            },
            {
                section: t("create.form.fields.6.section"),
                type: "text",
                name: "streetName",
                label: t("create.form.fields.6.label"),
            },
            {
                type: "text",
                name: "streetNumber",
                label: t("create.form.fields.7.label"),
            },
            {
                type: "text",
                name: "zipcode",
                label: t("create.form.fields.8.label"),
            },
            {
                type: "text",
                name: "city",
                label: t("create.form.fields.9.label"),
            },
            {
                type: "text",
                name: "provinceCode",
                label: t("create.form.fields.10.label"),
            },
            {
                section: t("create.form.fields.11.section"),
                type: "select",
                name: "partnerCode",
                label: t("create.form.fields.11.label"),
                options: partnerCodeList,
            },
            {
                type: "checkbox",
                name: "terms",
                label: (
                    <Trans
                        i18nKey="account:create.form.fields.12.label"
                        components={{
                            a: (
                                <a href={fileManagement.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
                                    Privacy Policy
                                </a>
                            ),
                        }}
                    />
                ),
            },
            {
                type: "optionsCheckbox",
                name: "optIn",
                label: t("create.form.fields.13.label"),
                options: [
                    {
                        label: t("create.form.fields.13.options.agree"),
                        value: true,
                    },
                    {
                        label: t("create.form.fields.13.options.disagree"),
                        value: false,
                    },
                ],
            },
        ],
        [t, partnerCodeList, fileManagement]
    );

    useEffect(() => {
        onHandleGetPartnerCode();
    }, []);

    const onHandleGetPartnerCode = async () => {
        try {
            const response = await api.get.partnerCode(countryCode);
            setPartnerCodeList(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const onHandleCreateAccount = async (values) => {
        try {
            const form = trimStrings(values);
            const optIns = form.optIn ? ["marketing"] : [];
            const payload = {
                countryCode: countryCode,
                emailConfirmationEnabled: process.env.REACT_APP_EMAIL_CONFIRMATION_ENABLED === "true" ? true : false,
                languageCode: i18n.language,
                firstName: form.firstName,
                lastName: form.lastName,
                dateOfBirth: null,
                email: form.email,
                idCard: form.vatNumber,
                idCardType: "vatNumber",
                phoneNumber: `${process.env.REACT_APP_PHONE_NUMBER_PREFIX}${form.phoneNumber}`,
                partnerCode: form.partnerCode,
                company: {
                    name: form.name,
                },
                address: {
                    countryCode: countryCode,
                    type: "correspondence",
                    building: null,
                    city: form.city,
                    district: null,
                    flat: null,
                    floor: null,
                    street: form.streetName,
                    zipCode: form.zipcode,
                    number: form.streetNumber,
                    region: null,
                    provinceCode: form.provinceCode,
                },
                optIns,
            };
            await api.post.createAccount(payload);
            dispatch(updateAlert({ message: "Account created." }));
            history.push(getPrefixUrl(pathnames.pageAccountSignIn));
        } catch (error) {
            onHandleRequestError(error);
        } finally {
            formik.setSubmitting(false);
        }
    };

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-account-create">
                <div className="account-create">
                    <div className="container">
                        <div className="col-md-6">
                            <h1 className="account-create__headline">{t("create.headline")}</h1>

                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                {inputs.map((input, index) => {
                                    const { sectionDescription, section, ...inputProps } = input;
                                    const type = input.type;
                                    const name = input.name;
                                    const value = formik.values[name];
                                    const touched = formik.touched[name];
                                    const error = formik.errors[name];
                                    const key = `create-account-input-${index}`;

                                    switch (type) {
                                        case "select":
                                            return (
                                                <Fragment key={key}>
                                                    {section ? <p className="account-create__category">{section}</p> : null}
                                                    <Input key={key} {...inputProps} touched={touched} error={error} value={value} disabled={!input.options.length} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                        {input.options.map((label, optionIndex) => {
                                                            const optionKey = `activate-plan-option-${optionIndex}-${label}`;
                                                            return (
                                                                <option key={optionKey} value={label}>
                                                                    {label}
                                                                </option>
                                                            );
                                                        })}
                                                    </Input>
                                                </Fragment>
                                            );
                                        case "checkbox":
                                            return <Checkbox key={key} touched={touched} error={error} label={input.label} onChange={(boolean) => formik.setFieldValue(name, boolean)} />;
                                        case "optionsCheckbox":
                                            return <OptionsCheckbox key={key} touched={touched} error={error} label={input.label} options={input.options} onChange={(boolean) => formik.setFieldValue(name, boolean)} />;
                                        default:
                                            return (
                                                <Fragment key={key}>
                                                    {section ? <p className="account-create__category">{section}</p> : null}
                                                    {sectionDescription ? <p className="account-create__description">{sectionDescription}</p> : null}
                                                    <Input {...inputProps} touched={touched} error={error} value={value} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                </Fragment>
                                            );
                                    }
                                })}

                                <div className="account-create__button-wrapper">
                                    <AppButton type="submit" label={t("create.form.submitButton")} disabled={formik.isSubmitting} isLoading={formik.isSubmitting} />
                                </div>
                            </form>

                            <p className="account-create__sign-in">
                                <Trans i18nKey="account:create.signIn" components={{ a: <AppPrefixLink className="account-create__link" to={pathnames.pageAccountSignIn} /> }} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageAccountCreate;
