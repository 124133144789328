import React, { Fragment, useMemo, useState, useEffect } from "react";
import * as Yup from "yup";
import moment from "moment";
import { useFormik } from "formik";
import { Spinner } from "reactstrap";
import cardValidator from "card-validator";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import useIsMount from "hooks/use-is-mount";
import usePrevious from "hooks/use-previous";
import { onHandleRequestError, getPrefixUrl, getMonthsRange, getYearsRange, trimStrings } from "common/utilities";
import Input from "components/form/input";
import AppButton from "components/shared/app-button";
import AppLayout from "components/shared/app-layout";
import { updateInitiateSwap } from "redux/slices/switch-slice";
import cardIcon from "assets/images/switch/card-icon.svg";
import cvcIcon from "assets/images/switch/cvc-icon.svg";

const creditCard = "creditCard";
const myBank = "myBank";
const pciDss = "pciDss";
const initialValues = {
    paymentMethod: creditCard,
    creditCardType: "",
    creditCardNumber: "",
    creditCardExpirationMonth: "",
    creditCardExpirationYear: "",
    creditCardSecurityCode: "",
    creditCardHolderFullName: "",
    myBank: "",
};

const isCreditCard = (value) => value === creditCard;
const isMyBank = (value) => value === myBank;
const PageSwitchPaymentDetails = () => {
    const isMount = useIsMount();
    const history = useHistory();
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state.auth);
    const { devices, summary, initateSwap } = useSelector((state) => state.switches);
    const invalidSwitchesDevice = devices.length === 0;
    const [fetchingPaymentMethods, setFetchingPaymentMethods] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [availableBanks, setAvailableBanks] = useState([]);
    const { t, i18n } = useTranslation("switches");
    const creditCardInputs = useMemo(
        () => [
            {
                type: "select",
                name: "creditCardType",
                label: t("paymentDetails.form.cardInformation.fields.0.label"),
                options: [
                    { label: "Visa", value: "visa" },
                    { label: "Master", value: "master" },
                ],
                colName: "col-md-12",
                icon: false,
            },
            {
                type: "text",
                name: "creditCardNumber",
                label: t("paymentDetails.form.cardInformation.fields.1.label"),
                colName: "col-md-12",
                icon: <img src={cardIcon} alt="" />,
            },
            {
                type: "select",
                name: "creditCardExpirationMonth",
                label: t("paymentDetails.form.cardInformation.fields.2.label"),
                options: getMonthsRange(12),
                colName: "col-md-6 pr-lg-2",
                icon: false,
            },
            {
                type: "select",
                name: "creditCardExpirationYear",
                maxLength: 2,
                label: t("paymentDetails.form.cardInformation.fields.3.label"),
                options: getYearsRange(20),
                colName: "col-md-6 pl-lg-2",
                icon: false,
            },
            {
                type: "text",
                name: "creditCardSecurityCode",
                maxLength: 4,
                label: t("paymentDetails.form.cardInformation.fields.4.label"),
                colName: "col-md-6 pr-lg-2",
                icon: <img src={cvcIcon} alt="" />,
            },
            {
                type: "text",
                name: "creditCardHolderFullName",
                label: t("paymentDetails.form.cardInformation.fields.5.label"),
                colName: "col-md-12",
                icon: false,
            },
        ],
        [t]
    );
    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                creditCardType: Yup.string().when("paymentMethod", { is: isCreditCard, then: Yup.string().required(t("paymentDetails.form.cardInformation.fields.0.required")) }),
                creditCardNumber: Yup.string().when("paymentMethod", { is: isCreditCard, then: Yup.string().required(t("paymentDetails.form.cardInformation.fields.1.required")).test("card-validation", t("paymentDetails.form.cardInformation.fields.1.required"), (value) => cardValidator.number(value).isValid) }),
                creditCardExpirationMonth: Yup.string().when("paymentMethod", { is: isCreditCard, then: Yup.string().required(t("paymentDetails.form.cardInformation.fields.2.required")) }),
                creditCardExpirationYear: Yup.string().when("paymentMethod", { is: isCreditCard, then: Yup.string().required(t("paymentDetails.form.cardInformation.fields.3.required")) }),
                creditCardSecurityCode: Yup.number().when("paymentMethod", { is: isCreditCard, then: Yup.number().required(t("paymentDetails.form.cardInformation.fields.4.required")).typeError(t("paymentDetails.form.cardInformation.fields.4.required")) }),
                creditCardHolderFullName: Yup.string().when("paymentMethod", { is: isCreditCard, then: Yup.string().required(t("paymentDetails.form.cardInformation.fields.5.required")) }),
                myBank: Yup.string().when("paymentMethod", { is: isMyBank, then: Yup.string().required(t("paymentDetails.form.myBank.fields.required")) }),
            }),
        [t]
    );
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: () => {
            onhandleInitiateSwitch();
        },
    });
    const previousFormikValues = usePrevious(formik.values);

    useEffect(() => {
        if (!previousFormikValues) return;

        if (previousFormikValues?.paymentMethod !== formik.values.paymentMethod) {
            formik.resetForm();
            formik.setFieldValue("paymentMethod", formik.values.paymentMethod);
        }
    }, [formik, previousFormikValues]);

    useEffect(() => {
        if (isMount) {
            const onHandleGetPaymentMethod = async () => {
                setFetchingPaymentMethods(true);
                try {
                    const paymentMethodsResponse = await api.get.paymentMethod();
                    const availableBanksResponse = await api.get.availableBanks(i18n.language);
                    setPaymentMethods(paymentMethodsResponse.data);
                    setAvailableBanks(availableBanksResponse.data);
                } catch (error) {
                    onHandleRequestError(error);
                } finally {
                    setFetchingPaymentMethods(false);
                }
            };
            onHandleGetPaymentMethod();
        }
    }, [isMount, i18n, dispatch]);

    const onhandleInitiateSwitch = async () => {
        try {
            const form = trimStrings(formik.values);
            const selectedPaymentMethod = formik.values.paymentMethod;
            const userContact = profile?.contact;
            const successURL = process.env.REACT_APP_REDIRECT_SWITCH_URL + getPrefixUrl(pathnames.pageSwitchSuccess);
            const failedURL = process.env.REACT_APP_REDIRECT_SWITCH_URL + getPrefixUrl(pathnames.pageSwitchSuccess);
            const initiateSwapRequests = devices.map((device) => {
                const deliveryDetails = device.switchContact;
                const replacementDevice = device.selectedSwitchDevice;
                const swapId = initateSwap?.initiateSwapRequests?.filter((item) => item.swap.subscriptionId === device.subscriptionId)[0]?.swap?.id;
                const deliveryPhoneNumber = device.switchContact.phoneNumber.includes(process.env.REACT_APP_PHONE_NUMBER_PREFIX) ? device.switchContact.phoneNumber : `${process.env.REACT_APP_PHONE_NUMBER_PREFIX}${deliveryDetails.phoneNumber}`;

                return {
                    swap: {
                        id: swapId || null,
                        deliveryDetails: {
                            firstName: deliveryDetails.firstName,
                            lastName: deliveryDetails.lastName,
                            phoneNumber: deliveryPhoneNumber,
                            optIns: [],
                            email: deliveryDetails.email,
                            ids: userContact.ids,
                            dateOfBirth: null,
                            addresses: [
                                {
                                    id: null,
                                    countryCode: userContact.countryCode,
                                    type: "",
                                    building: null,
                                    city: deliveryDetails.city,
                                    district: null,
                                    flat: null,
                                    floor: null,
                                    street: deliveryDetails.street,
                                    zipCode: deliveryDetails.zipCode,
                                    number: deliveryDetails.number,
                                    region: null,
                                    area: null,
                                    subDistrict: null,
                                    provinceCode: deliveryDetails.provinceCode,
                                },
                            ],
                            gender: null,
                            countryCode: userContact.countryCode,
                        },
                        subscriptionId: device.subscriptionId,
                        coveredDevice: {
                            imei: device.imei,
                            articleId: device.articleId,
                            sku: device.sku,
                            purchaseDate: null,
                            includedVat: null,
                            suggestedRetailPrice: device.suggestedRetailPrice,
                            stockLocation: device.stockLocation,
                        },
                        replacementDevice : {
                            imei: null,
                            articleId: replacementDevice.deviceId,
                            sku: replacementDevice.deviceSku,
                            purchaseDate: null,
                            includedVat: null,
                            purchasePrice: null,
                            suggestedRetailPrice: replacementDevice.suggestedRetailPrice,
                            stockLocation: replacementDevice.deviceStockLocation,
                        },
                        countryCode: userContact.countryCode,
                    },
                    skipPreauthorization: true,
                };
            });
            const payload = {
                initiateSwapRequests,
                paymentMethod: null,
            };

            switch (selectedPaymentMethod) {
                case creditCard:
                    payload.paymentMethod = {
                        type: creditCard,
                        details: {
                            creditCardType: form.creditCardType,
                            creditCardHolderFullName: form.creditCardHolderFullName,
                            creditCardNumber: form.creditCardNumber,
                            creditCardSecurityCode: form.creditCardSecurityCode,
                            creditCardExpirationMonth: form.creditCardExpirationMonth,
                            creditCardExpirationYear: form.creditCardExpirationYear,
                            prefer3DS: true,
                            returnSuccessUrlPath: successURL,
                            returnFailUrlPath: failedURL,
                            returnUrlHost: process.env.REACT_APP_API_URL,
                        },
                    };
                    break;
                case myBank:
                    payload.paymentMethod = {
                        type: myBank,
                        details: {
                            bankId: form.myBank,
                            returnSuccessUrlPath: successURL,
                            returnFailUrlPath: failedURL,
                            returnUrlHost: process.env.REACT_APP_API_URL,
                        },
                    };
                    break;
                case pciDss:
                    payload.paymentMethod = {
                        type: pciDss,
                        details: {
                            returnSuccessUrlPath: successURL,
                            returnFailUrlPath: failedURL,
                            returnUrlHost: process.env.REACT_APP_API_URL,
                        },
                    };
                    break;
                default:
                    break;
            }
            const response = await api.post.initiateSwitch(payload);
            const paymentInstructions = response.data.paymentInstructions;

            dispatch(updateInitiateSwap(response.data));

            switch (paymentInstructions.action) {
                case "redirect":
                    window.location.href = response.data.paymentInstructions.payload;
                    break;
                case "formPost":
                    document.open();
                    document.write(response.data.paymentInstructions.payload);
                    document.close();
                    break;
                default:
                    history.push(getPrefixUrl(pathnames.pageSwitchSuccess));
                    break;
            }
        } catch (error) {
            onHandleRequestError(error, formik.setSubmitting);
        }
    };

    if (invalidSwitchesDevice) return <Redirect to={getPrefixUrl(pathnames.pageAccountMySwitch)} />;

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-switch-payment-details">
                <div className="switch-payment-details">
                    <div className="switch-payment-details__container container">
                        <div className="row">
                            <div className="switch-payment-details__col switch-payment-details__col--payment col-lg-8 col-md-12">
                                {fetchingPaymentMethods && <Spinner className="app-spinner" />}

                                {!fetchingPaymentMethods && (
                                    <form className="switch-payment-details__form" autoComplete="off" onSubmit={formik.handleSubmit}>
                                        <div className="switch-payment-details__header">
                                            <h1 className="switch-payment-details__headline">{t("paymentDetails.headline")}</h1>
                                            <p className="switch-payment-details__text switch-payment-details__text--price">
                                                {t("paymentDetails.description")}
                                                <span>
                                                    {summary.total.amount} {summary.total.currencyCode}
                                                </span>
                                            </p>
                                        </div>

                                        <p className="switch-payment-details__text">{t("paymentDetails.form.paymentMethod.section")}</p>

                                        <Input type="select" name="paymentMethod" icon={<img src={cardIcon} alt="" />} value={formik.values.paymentMethod} touched={formik.touched.paymentMethod} error={formik.errors.paymentMethod} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                            {paymentMethods.map((method, index) => {
                                                const methodKey = `payment-method-${index}`;
                                                return (
                                                    <option key={methodKey} value={method}>
                                                        {t(`paymentDetails.form.paymentMethod.fields.${method}`)}
                                                    </option>
                                                );
                                            })}
                                        </Input>

                                        {formik.values.paymentMethod === myBank && (
                                            <Fragment>
                                                <p className="switch-payment-details__text">{t("paymentDetails.form.myBank.section")}</p>
                                                <Input type="select" name="myBank" value={formik.values.myBank} touched={formik.touched.myBank} error={formik.errors.myBank} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option value="" hidden>
                                                        {t(`paymentDetails.form.myBank.fields.label`)}
                                                    </option>
                                                    {availableBanks.map((bank, index) => {
                                                        const bankKey = `payment-method-${index}`;
                                                        return (
                                                            <option key={bankKey} value={bank.bankId}>
                                                                {bank.bankName}
                                                            </option>
                                                        );
                                                    })}
                                                </Input>
                                            </Fragment>
                                        )}

                                        {formik.values.paymentMethod === creditCard && (
                                            <Fragment>
                                                <p className="switch-payment-details__text switch-payment-details__text--card-info">{t("paymentDetails.form.cardInformation.section")}</p>
                                                <div className="row">
                                                    {creditCardInputs.map((input, index) => {
                                                        const { section, colName, defaultOption, options, ...inputProps } = input;
                                                        const key = `payment-details-input-${index}`;
                                                        const name = input.name;
                                                        const touched = formik.touched[name];
                                                        const error = formik.errors[name];
                                                        const value = formik.values[name];

                                                        switch (input.type) {
                                                            case "select":
                                                                return (
                                                                    <div key={key} className={colName}>
                                                                        <Input {...inputProps} error={error} touched={touched} value={value} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                                            <option value="" hidden></option>
                                                                            {options.map((opt, optIndex) => {
                                                                                const key = `option-${optIndex}`;
                                                                                return (
                                                                                    <option key={key} value={opt.value}>
                                                                                        {opt.label}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </Input>
                                                                    </div>
                                                                );
                                                            default:
                                                                return (
                                                                    <div key={key} className={colName}>
                                                                        <Input {...inputProps} error={error} touched={touched} value={value} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                                    </div>
                                                                );
                                                        }
                                                    })}
                                                </div>
                                            </Fragment>
                                        )}

                                        <div className="switch-payment-details__button-wrapper">
                                            <AppButton label={t("paymentDetails.form.submitButton")} type="submit" disabled={formik.isSubmitting} isLoading={formik.isSubmitting} />
                                        </div>
                                    </form>
                                )}
                            </div>

                            <div className="switch-payment-details__col switch-payment-details__col--background col-lg-4">
                                <p className="switch-payment-details__disclaimer">Disclaimer © {moment().year()} bolttech</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageSwitchPaymentDetails;
