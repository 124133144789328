import { useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";

import history from "routes/history";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import { sanitizeError, getPrefixUrl } from "common/utilities";
import { showModal, resetModal } from "redux/slices/session-expired-modal-slice";

const sharedStyles = "margin: 5px 0; padding: 8px; border-radius: 20px; color: #FFFFFF;";
const initStyles = `${sharedStyles} background: #673AB7;`;
const reqSuccessStyles = `${sharedStyles} background: #F8EA8C;`;
const respSuccessStyles = `${sharedStyles} background: #8BC34A;`;
const errorStyles = `${sharedStyles} background: #F79489;`;
const debug = CONSTANSTS.IS_DEVELOPMENT_MODE;

const interceptorDebug = (title, styles, data = "") => {
    if (!debug) return;

    console.log(`%c ${title}`, styles);
    console.log(data);
    console.log("");
};

const InterceptorProvider = ({ children }) => {
    const dispatch = useDispatch();
    const { sessionExpiredModal } = useSelector((state) => state);
    const isMount = useIsMount();
    const sessionInterval = useRef();
    const requestInterceptor = useRef();
    const responseInterceptor = useRef();
    const dispatchedModal = useRef(false);

    const onHandleForceLogout = useCallback(() => {
        const signInPage = getPrefixUrl(pathnames.pageAccountSignIn);
        const isNotSignInPage = history.location.pathname !== signInPage;

        if (isNotSignInPage) {
            history.push(signInPage);
        }

        const logoutAction = { type: "logout" };

        clearInterval(sessionInterval.current);
        dispatch(logoutAction);
        localStorage.clear();
    }, [dispatch]);

    const onHandleSetToken = (token) => {
        if (token) localStorage.setItem("@storage_token", token);
    };

    const onHandleSessionTimer = useCallback(({ sessionExpirationDate, warningExpiredDate }) => {
            const token = localStorage.getItem("@storage_token");
            const now = moment().valueOf();
            const isShowSessionExpiredModal = now > warningExpiredDate;
            const isSessionExpired = now > sessionExpirationDate;

            const userLogoutStopInterval = () => {
                if (!token) clearInterval(sessionInterval.current);
            };
            
            userLogoutStopInterval();

            const userLogoutDismissModal = () => {
                if (!token) dispatch(resetModal());
            };
            userLogoutDismissModal();

            if (isShowSessionExpiredModal && !dispatchedModal.current) {
                dispatch(showModal());
            }
            if (isSessionExpired) {
                onHandleForceLogout();
            }
        },
        [dispatch, onHandleForceLogout]
    );

    const onHandleSessionTimeout = useCallback((inactiveIntervalMins) => {
            if (!debug) return;

            let maxIntervalMins = inactiveIntervalMins ? +inactiveIntervalMins : null;
            const warningInminutes = 10;
            const warningExpiredDate = moment().add(warningInminutes, "minutes").valueOf();
            const token = localStorage.getItem("@storage_token");

            if (!token) return;

            if (sessionInterval.current) clearInterval(sessionInterval.current);

            if (maxIntervalMins) {
                localStorage.setItem("@storage_inactiveIntervalMins", maxIntervalMins);
            }

            if (!maxIntervalMins) {
                maxIntervalMins = localStorage.getItem("@storage_inactiveIntervalMins");
            }

            const sessionExpirationDate = moment().add(maxIntervalMins, "minutes").valueOf();

            if (sessionInterval.current) clearInterval(sessionInterval.current);

            sessionInterval.current = setInterval(() => onHandleSessionTimer({ sessionExpirationDate, warningExpiredDate }), 1000);
        },
        [onHandleSessionTimer]
    );

    useEffect(() => {
        if (isMount) {
            interceptorDebug("Init Axios Interceptor! 🎉", initStyles);
            
            requestInterceptor.current = axios.interceptors.request.use(
                (config) => {
                    interceptorDebug("REQUESTING 🚀", reqSuccessStyles, config.url);
                    return config;
                },
                (error) => {
                    interceptorDebug("REQUESTING ERROR 👎", errorStyles, sanitizeError(error));
                    return Promise.reject(error);
                }
            );
            
            responseInterceptor.current = axios.interceptors.response.use(
                (response) => {
                    const xRefreshToken = response.headers["x-refresh-token"];
                    const xMaxInactiveInterval = response.headers["x-max-inactive-interval"];

                    onHandleSetToken(xRefreshToken);
                    onHandleSessionTimeout(xMaxInactiveInterval);
                    interceptorDebug("RESPONSE SUCCESS: 🌟", respSuccessStyles, response.data);
                    
                    return response;
                },
                async (error) => {
                    interceptorDebug("RESPONSE ERROR 🥲", errorStyles, sanitizeError(error));
                    const statusCode = error?.response?.status;

                    switch (statusCode) {
                        case 401:
                            onHandleForceLogout();
                            return Promise.reject(error);
                        default:
                            return Promise.reject(error);
                    }
                }
            );

        }
    }, [isMount, onHandleForceLogout, onHandleSessionTimeout]);

    useEffect(() => {
        dispatchedModal.current = sessionExpiredModal.active;
    },[sessionExpiredModal.active])

    useEffect(() => {
        return () => {
            interceptorDebug("Ejected Axios Interceptor! 👋", initStyles);
            axios.interceptors.request.eject(requestInterceptor.current);
            axios.interceptors.response.eject(responseInterceptor.current);
            clearInterval(sessionInterval.current);
        };
    }, []);
    
    return children;
};

export default InterceptorProvider;
