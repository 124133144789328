import React, { useEffect, useState, useMemo, useRef, Fragment } from "react";
import { Spinner } from "reactstrap";
import { IoAdd, IoClose } from "react-icons/io5";
import { useTranslation, Trans } from "react-i18next";

import api from "services/api";
import useIsMount from "hooks/use-is-mount";
import AppLayout from "components/shared/app-layout";
import AppButton from "components/shared/app-button";
import switchModelColorIcon from "assets/images/about/switch-color-model.svg";
import switchForAnyReason from "assets/images/about/switch-for-any-reason.svg";
import requestOnlineIcon from "assets/images/about/request-online.svg";
import freeDeliveryIcon from "assets/images/about/free-delivery.svg";
import chooseNewDeviceIcon from "assets/images/about/choose-new-device.svg";
import verificationConfirmationIcon from "assets/images/about/verification-confirmation.svg";
import receivedAtYourDoorstepIcon from "assets/images/about/received-at-your-doorstep.svg";
import howToSwitch from "assets/images/about/how-to-switch.svg";

const country = process.env.REACT_APP_COUNTRY_CODE;

const getEligibilityDeviceClassName = (selected) => {
    const classNames = ["eligibility__device col-md-6 col-lg-4"];

    if (selected) classNames.push("eligibility__device--selected");

    return classNames.join(" ");
};

const getEligibilityAccordionClassNames = (active) => {
    const classNames = ["eligibility__accordion"];

    if (active) classNames.push("is-active");

    return classNames.join(" ");
};

const PageAbout = () => {
    const isMount = useIsMount();
    const { t, i18n } = useTranslation("about");
    const faqs = useMemo(() => i18n.t("about:faq", { returnObjects: true }), [i18n]);
    const mySwitchView = useRef();
    const uniqRecommendedList = useRef([]);
    const originalEligibleDevices = useRef(null);
    const [recommendedList, setRecommendedList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [fetchingEligibleDevices, setFetchingEligibleDevices] = useState(false);
    const locale = useMemo(() => `${i18n.language}_${country}`, [i18n]);
    const [eligibleDevices, setEligibleDevices] = useState([]);

    useEffect(() => {
        const onHandleGetAllEligibleDevices = async () => {
            setFetchingEligibleDevices(true);
            try {
                const response = await api.get.allEligibleDevices(locale);
                const devices = response.data.map((o) => o.devices).flat();
                const uniqDevices = [...new Set(devices)];
                uniqRecommendedList.current = uniqDevices;
                originalEligibleDevices.current = response.data;

                setEligibleDevices(response.data);
            } catch (error) {
                console.error(error);
            } finally {
                setFetchingEligibleDevices(false);
            }
        };

        if (isMount) {
            onHandleGetAllEligibleDevices();
        }
    }, [isMount, locale]);

    const onHandleSearchDevicePlan = (event) => {
        const value = event.currentTarget.value;
        const trimmedValue = event.currentTarget.value.toLowerCase().trim();
        const recommendedDevices = uniqRecommendedList.current.filter((sub) => sub.toLowerCase().includes(trimmedValue));
        setSearchValue(value);

        if (value) {
            setRecommendedList(recommendedDevices);
            getDevicePlans(value);
        } else {
            setRecommendedList([]);
            setEligibleDevices(originalEligibleDevices.current);
        }
    };

    const onHandleBlurSearchInput = () => {
        setRecommendedList([]);
    };

    const onHandleSelectRecommendedItem = (value) => {
        setSearchValue(value);
        setRecommendedList([]);

        if (value) {
            getDevicePlans(value);
        }
    };

    const onHandleClearSearchInput = () => {
        setSearchValue("");
        setRecommendedList([]);
        setEligibleDevices(originalEligibleDevices.current);
    };

    const getDevicePlans = (value) => {
        const trimmedValue = value.toLowerCase().trim();
        const plans = originalEligibleDevices.current.filter((plan) => {
            return plan.devices.filter((device) => device.toLowerCase().includes(trimmedValue)).length;
        });

        setEligibleDevices(plans);
    };

    const toggleAccordion = (event) => {
        const currentTrigger = event.currentTarget;
        const item = currentTrigger.parentNode;
        item.classList.toggle("is-active");
    };

    const scrollIntoSwitchView = () => {
        mySwitchView?.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-about">
                <div className="about">
                    <div className="banner">
                        <div className="container banner__container">
                            <div className="banner__content">
                                <h1 className="banner__headline">{t("banner.headline")}</h1>

                                <div className="banner__button">
                                    <AppButton label={t("banner.button")} onClick={scrollIntoSwitchView} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <p className="about__description">{t("description")}</p>

                        <div className="usp">
                            <div className="usp__item">
                                <img className="usp__icon" src={switchModelColorIcon} alt="" />
                                <p className="usp__text">{t("usp.0.label")}</p>
                            </div>
                            <div className="usp__item">
                                <img className="usp__icon" src={requestOnlineIcon} alt="" />
                                <p className="usp__text">{t("usp.1.label")}</p>
                            </div>
                            <div className="usp__item">
                                <img className="usp__icon" src={switchForAnyReason} alt="" />
                                <p className="usp__text">{t("usp.2.label")}</p>
                            </div>
                            <div className="usp__item">
                                <img className="usp__icon" src={freeDeliveryIcon} alt="" />
                                <p className="usp__text">{t("usp.3.label")}</p>
                            </div>
                        </div>
                    </div>

                    <div className="activate">
                        <div className="container activate__container">
                            <div className="activate__content">
                                <p className="activate__headline">{t("activate.headline")}</p>
                                <div className="activate__description">
                                    <Trans i18nKey="about:activate.description" />
                                </div>
                            </div>
                            <div className="activate__image" />
                        </div>
                    </div>

                    <div className="switch" ref={mySwitchView}>
                        <div className="container">
                            <p className="switch__headline">{t("switch.headline")}</p>

                            <div className="switch__content">
                                <div className="switch__image">
                                    <img src={howToSwitch} alt="" />
                                </div>
                                <div className="switch__list">
                                    <div className="switch__item">
                                        <img src={chooseNewDeviceIcon} alt="" />
                                        <p className="switch__text">{t("switch.steps.0.label")}</p>
                                    </div>
                                    <div className="switch__item">
                                        <img src={verificationConfirmationIcon} alt="" />
                                        <p className="switch__text">{t("switch.steps.1.label")}</p>
                                    </div>
                                    <div className="switch__item">
                                        <img src={receivedAtYourDoorstepIcon} alt="" />
                                        <p className="switch__text">{t("switch.steps.2.label")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="about__accordion">
                            <button className="about__toggle-button" onClick={toggleAccordion}>
                                <p className="about__label">{t("faq.section")}</p>
                                <IoAdd className="about__plus-icon" size={25} />
                            </button>

                            <div className="faq">
                                {faqs?.questions?.map((item, index) => {
                                    const key = `faq-${index}`;
                                    return (
                                        <div key={key} className="faq__accordion">
                                            <button className="faq__toggle-button" onClick={toggleAccordion}>
                                                {item.question}
                                                <IoAdd className="faq__plus-icon" />
                                            </button>

                                            <div className="faq__content">
                                                <p className="faq__description" dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="about__accordion">
                            <button className="about__toggle-button" onClick={toggleAccordion}>
                                <p className="about__label">{t("eligibility.section")}</p>
                                <IoAdd className="about__plus-icon" size={25} />
                            </button>

                            <div className="eligibility">
                                {fetchingEligibleDevices ? (
                                    <div className="eligibility__loading">
                                        <Spinner className="app-spinner" />
                                    </div>
                                ) : (
                                    <Fragment>
                                        <div className="search-input">
                                            <p className="search-input__label">{t("eligibility.search.label")}</p>

                                            <div className="search-input__wrapper">
                                                <div className="search-input__inner-wrapper">
                                                    <input
                                                        name="device"
                                                        className="search-input__input"
                                                        placeholder={t("eligibility.search.placeholder")}
                                                        value={searchValue}
                                                        disabled={!originalEligibleDevices.current}
                                                        onChange={onHandleSearchDevicePlan}
                                                        onBlur={onHandleBlurSearchInput}
                                                    />

                                                    {searchValue && (
                                                        <button className="search-input__clear-button" onClick={onHandleClearSearchInput}>
                                                            <IoClose />
                                                        </button>
                                                    )}
                                                </div>

                                                {!!recommendedList.length && (
                                                    <ul className="search-input__recommended-list">
                                                        {recommendedList.map((item, index) => {
                                                            const recommendedKey = `recommended-item-${index}`;
                                                            return (
                                                                <li className="search-input__recommended-item" key={recommendedKey} onMouseDown={() => onHandleSelectRecommendedItem(item)}>
                                                                    {item}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>

                                        {eligibleDevices.map((item, index) => {
                                            const key = `eligible-devices-${index}`;
                                            const oneEligiblePlanOnly = eligibleDevices.length === 1;

                                            return (
                                                <div key={key} className={getEligibilityAccordionClassNames(oneEligiblePlanOnly)}>
                                                    <button className="eligibility__toggle-button" onClick={toggleAccordion}>
                                                        Plan / {item.planCode}
                                                        <IoAdd className="eligibility__plus-icon" />
                                                    </button>

                                                    <div className="eligibility__content">
                                                        <p className="eligibility__description">{item.description}</p>
                                                        <div className="row">
                                                            {item.devices.map((device, deviceIndex) => {
                                                                const key = `${device}-${deviceIndex}`;
                                                                const trimmedSearchValue = searchValue.toLowerCase().trim();
                                                                const includedInSearch = trimmedSearchValue && device.toLowerCase().includes(trimmedSearchValue);

                                                                return (
                                                                    <p className={getEligibilityDeviceClassName(includedInSearch)} key={key}>
                                                                        {device}
                                                                    </p>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageAbout;
