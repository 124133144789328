import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import AppLayout from "components/shared/app-layout";
import AppSideNav from "components/pages/account/app-side-nav";

import icloudIcon from "assets/images/backup-recovery/icloud.png";
import settingsIcon from "assets/images/backup-recovery/ios-settings-icon.png";
import backupAndroidStep1 from "assets/images/backup-recovery/backup-ios-step-1.png";
import backupAndroidStep2 from "assets/images/backup-recovery/backup-ios-step-2.png";
import backupAndroidStep3 from "assets/images/backup-recovery/backup-ios-step-3.png";
import recoveryAndroidStep1 from "assets/images/backup-recovery/recovery-ios-step-1.png";
import recoveryAndroidStep2 from "assets/images/backup-recovery/recovery-ios-step-2.png";
import recoveryAndroidStep3 from "assets/images/backup-recovery/recovery-ios-step-3.png";

const PageAccountRecoveryAndroid = () => {
    const { t } = useTranslation("account");
    const [activeTab, setActiveTab] = useState("1");
    const tabs = [
        {
            label: t("backupRecovery.ios.tabs.0.label"),
        },
        {
            label: t("backupRecovery.ios.tabs.1.label"),
        },
    ];

    const toggle = (event) => {
        const tab = event.target.attributes.getNamedItem("data-tab").value;
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-account-backup-recovery-ios">
                <div className="backup-recovery-ios">
                    <div className="backup-recovery-ios__container container">
                        <div className="backup-recovery-ios__wrapper">
                            <AppSideNav />

                            <div className="backup-recovery-ios__content">
                                <h1 className="backup-recovery-ios__title">{t("backupRecovery.ios.headline")}</h1>

                                <Nav tabs className="backup-recovery-ios__tabs">
                                    {tabs.map((item, index) => {
                                        const key = `tab-${index}`;
                                        const dataTab = (index + 1).toString();

                                        return (
                                            <NavItem className="backup-recovery-ios__tab" key={key}>
                                                <NavLink data-tab={dataTab} className={classnames({ active: activeTab === dataTab })} onClick={toggle}>
                                                    {item.label}
                                                </NavLink>
                                            </NavItem>
                                        );
                                    })}
                                </Nav>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <div className="service">
                                            <img className="service__img" src={icloudIcon} alt="" />
                                            <div className="service__content">
                                                <p className="service__note">{t("backupRecovery.ios.backup.service.note")}</p>
                                                <p className="service__title">
                                                    <Trans i18nKey="account:backupRecovery.ios.backup.service.title" />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tutorial">
                                            <div className="tutorial__step">
                                                <div className="tutorial__header">
                                                    <p className="tutorial__title">{t("backupRecovery.ios.backup.tutorial.0.title")}</p>
                                                    <p className="tutorial__description">{t("backupRecovery.ios.backup.tutorial.0.description")}</p>
                                                </div>

                                                <img src={settingsIcon} alt="" />

                                                <div className="tutorial__img">
                                                    <img src={backupAndroidStep1} alt="" />
                                                </div>
                                                <ul className="tutorial__settings">
                                                    <li className="tutorial__setting-step">
                                                        <div className="tutorial__badge">
                                                            <span>1</span>
                                                        </div>
                                                        <p>
                                                            <Trans i18nKey="account:backupRecovery.ios.backup.tutorial.0.settings.0.label" />
                                                        </p>
                                                    </li>
                                                    <li className="tutorial__setting-step">
                                                        <div className="tutorial__badge">
                                                            <span>2</span>
                                                        </div>
                                                        <p>{t("backupRecovery.ios.backup.tutorial.0.settings.1.label")}</p>
                                                    </li>
                                                    <li className="tutorial__setting-step">
                                                        <div className="tutorial__badge">
                                                            <span>3</span>
                                                        </div>
                                                        <p>
                                                            <Trans i18nKey="account:backupRecovery.ios.backup.tutorial.0.settings.2.label" />
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="tutorial__step">
                                                <div className="tutorial__header">
                                                    <p className="tutorial__title">{t("backupRecovery.ios.backup.tutorial.1.title")}</p>
                                                    <p className="tutorial__description">
                                                        <Trans i18nKey="account:backupRecovery.ios.backup.tutorial.1.description" />
                                                    </p>
                                                </div>
                                                <div className="tutorial__img">
                                                    <img src={backupAndroidStep2} alt="" />
                                                </div>
                                                <ul className="tutorial__settings">
                                                    <li className="tutorial__setting-step">
                                                        <div className="tutorial__badge">
                                                            <span>4</span>
                                                        </div>
                                                        <p>
                                                            <Trans i18nKey="account:backupRecovery.ios.backup.tutorial.1.settings.0.label" />
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="tutorial__step">
                                                <div className="tutorial__header">
                                                    <p className="tutorial__title">{t("backupRecovery.ios.backup.tutorial.2.title")}</p>
                                                    <p className="tutorial__description">
                                                        <Trans i18nKey="account:backupRecovery.ios.backup.tutorial.2.description" />
                                                    </p>
                                                </div>
                                                <div className="tutorial__img">
                                                    <img src={backupAndroidStep3} alt="" />
                                                </div>
                                                <ul className="tutorial__settings">
                                                    <li className="tutorial__setting-step">
                                                        <div className="tutorial__badge">
                                                            <span>5</span>
                                                        </div>
                                                        <p>
                                                            <Trans i18nKey="account:backupRecovery.ios.backup.tutorial.2.settings.0.label" />
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="service">
                                            <img className="service__img" src={icloudIcon} alt="" />
                                            <div className="service__content">
                                                <p className="service__note">{t("backupRecovery.ios.recovery.service.note")}</p>
                                                <p className="service__title">
                                                    <Trans i18nKey="account:backupRecovery.ios.recovery.service.title" />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tutorial">
                                            <div className="tutorial__step">
                                                <div className="tutorial__header">
                                                    <p className="tutorial__title">{t("backupRecovery.ios.recovery.tutorial.0.title")}</p>
                                                    <p className="tutorial__description">{t("backupRecovery.ios.recovery.tutorial.0.description")}</p>
                                                </div>

                                                <img src={settingsIcon} alt="" />

                                                <div className="tutorial__img">
                                                    <img src={recoveryAndroidStep1} alt="" />
                                                </div>
                                                <ul className="tutorial__settings">
                                                    <li className="tutorial__setting-step">
                                                        <div className="tutorial__badge">
                                                            <span>1</span>
                                                        </div>
                                                        <p>
                                                            <Trans i18nKey="account:backupRecovery.ios.recovery.tutorial.0.settings.0.label" />
                                                        </p>
                                                    </li>
                                                    <li className="tutorial__setting-step">
                                                        <div className="tutorial__badge">
                                                            <span>2</span>
                                                        </div>
                                                        <p>{t("backupRecovery.ios.recovery.tutorial.0.settings.1.label")}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="tutorial__step">
                                                <div className="tutorial__header">
                                                    <p className="tutorial__title">{t("backupRecovery.ios.recovery.tutorial.1.title")}</p>
                                                    <p className="tutorial__description">
                                                        <Trans i18nKey="account:backupRecovery.ios.recovery.tutorial.1.description" />
                                                    </p>
                                                </div>
                                                <div className="tutorial__img">
                                                    <img src={recoveryAndroidStep2} alt="" />
                                                </div>
                                                <ul className="tutorial__settings">
                                                    <li className="tutorial__setting-step">
                                                        <div className="tutorial__badge">
                                                            <span>4</span>
                                                        </div>
                                                        <p>
                                                            <Trans i18nKey="account:backupRecovery.ios.recovery.tutorial.1.settings.0.label" />
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="tutorial__step">
                                                <div className="tutorial__header">
                                                    <p className="tutorial__title">{t("backupRecovery.ios.recovery.tutorial.2.title")}</p>
                                                    <p className="tutorial__description">
                                                        <Trans i18nKey="account:backupRecovery.ios.recovery.tutorial.2.description" />
                                                    </p>
                                                </div>
                                                <div className="tutorial__img">
                                                    <img src={recoveryAndroidStep3} alt="" />
                                                </div>
                                                <ul className="tutorial__settings">
                                                    <li className="tutorial__setting-step">
                                                        <div className="tutorial__badge">
                                                            <span>5</span>
                                                        </div>
                                                        <p>
                                                            <Trans i18nKey="account:backupRecovery.ios.recovery.tutorial.2.settings.0.label" />
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageAccountRecoveryAndroid;
