import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IoChevronForwardSharp } from "react-icons/io5";

import api from "services/api";
import pathnames from "routes/pathnames";
import { onHandleRequestError, getPrefixUrl } from "common/utilities";
import useIsMount from "hooks/use-is-mount";
import AppLayout from "components/shared/app-layout";
import AppPrefixLink from "components/shared/app-prefix-link";

const fail = "fail";
const success = "success";
const pending = "pending";

const PageSwitchSuccess = () => {
    const isMount = useIsMount();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation("switches");
    const { profile } = useSelector((state) => state.auth);
    const { initateSwap } = useSelector((state) => state.switches);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [fetchingPaymentStatus, setFetchingPaymentStatus] = useState(true);

    useEffect(() => {
        if (isMount) {
            const onHandleVerifyPaymentStatus = async () => {
                try {
                    const response = await api.get.paymentStatus(initateSwap?.bulkSwap?.id);
                    const status = response.data.status;
                    setPaymentStatus(status);

                    switch (status) {
                        case success:
                            setFetchingPaymentStatus(false);
                            break;
                        case pending:
                            setTimeout(() => {
                                onHandleVerifyPaymentStatus();
                            }, 3000);
                            break;
                        case fail:
                            setFetchingPaymentStatus(false);
                            break;
                        default:
                            break;
                    }
                } catch (error) {
                    onHandleRequestError(error, setFetchingPaymentStatus);
                }
            };

            if (initateSwap.bulkSwap) {
                onHandleVerifyPaymentStatus();
            } else {
                history.replace(getPrefixUrl(pathnames.pageAccountMySwitch));
            }
        }
    }, [isMount, dispatch, history, initateSwap]);

    if (fetchingPaymentStatus) {
        return (
            <AppLayout hasHeader hasFooter>
                <div className="app-page page-switch-success">
                    <div className="switch-success">
                        <div className="container d-flex justify-content-center">
                            <Spinner className="app-spinner" />
                        </div>
                    </div>
                </div>
            </AppLayout>
        );
    }

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-switch-success">
                <div className="switch-success">
                    <div className="container">
                        {paymentStatus === fail && (
                            <div className="banner">
                                <h1 className="banner__headline">{t("fail.headline", { firstName: profile.contact.firstName })}</h1>
                                <p className="banner__sub-headline">{t("fail.subHeadline")}</p>
                                <p className="banner__description">{t("fail.description")}</p>

                                <div className="banner__button">
                                    <AppPrefixLink className="app-button" to={pathnames.pageSwitchPaymentDetails}>
                                        <span className="app-button__text">{t("fail.button")}</span>
                                        <IoChevronForwardSharp className="app-button__icon" />
                                    </AppPrefixLink>
                                </div>
                            </div>
                        )}

                        {paymentStatus === success && (
                            <div className="banner">
                                <h1 className="banner__headline">{t("success.headline")}</h1>
                                <p className="banner__description">{t("success.description")}</p>

                                <div className="banner__button">
                                    <AppPrefixLink className="app-button" to={pathnames.pageAccountMySwitch}>
                                        <span className="app-button__text">{t("success.button")}</span>
                                        <IoChevronForwardSharp className="app-button__icon" />
                                    </AppPrefixLink>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageSwitchSuccess;
