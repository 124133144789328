const CONSTANSTS = {
    IS_DEVELOPMENT_MODE: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
    ENDPOINT_PATH: {
        SIGN_IN: "/account/sign-in",
        SIGN_IN_WITH_EMAIL: "/account/sign-in-with-email",
        PROFILE: "/account",
        UPDATE_PROFILE: "/account/updateContact",
        CREATE_ACCOUNT: "/account/register",
        PARTNER_CODE: "/partnerCode/byCountryCode",
        REDEEM_SUBSCRIPTIONS: "/subscription/list",
        PLANS: "/plan/getPlans?orderBy=internalId&sort=asc",
        ELIGIBLE_DEVICE: "/plan/priceTier/eligibleDevice",
        PRICE_TIER: "/plan/priceTier",
        CREATE_SUBSCRIPTIONS: "/subscription/createPartnerSubscriptions",
        SEARCH_ARTICLE: "/search/articles",
        TERMS_AND_CONDITIONS_LINKS: "/config/getTermsAndConditionsLinks",
        ALL_ELIGIBLE_DEVICES: "/general/getAllEligibleDevices",
        PAYMENT_METHOD: "/payment/getPaymentMethod/partnerportal/channel/frontend",
        AVAILABLE_BANKS: "/payment/getAvailableBanks/myBank",
        SWITCH_DEVICES: "/swap/deviceSelections",
        SWITCH_SUMMARY: "/swap/summary",
        INITIATE_SWITCH: "/swap/initiate",
        SWITCH_PAYMENT_STATUS: "/swap/paymentStatus/",
        CAPTCHA_ENABLED: "/captcha/getIsCaptchaEnabled?countryCode=IT",
    },
    DATE_FORMAT: {
        YYYYMMDD: "YYYY-MM-DD",
        DDMMYYYYY: "DD-MM-YYYY",
        DDMMYYYYY_HHMMA: "DD-MM-YYYY hh:mm A",
    },
    DEVICE_PLANS: {
        likeForIdentical: "Like For Identical",
        likeForSimilar: "Like For Similar",
        likeForLike: "Like For Like",
    },
};

export default CONSTANSTS;
