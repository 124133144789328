import React, { useRef, useState, useEffect, useMemo } from "react";
import * as Yup from "yup";
import moment from "moment";
import { useFormik } from "formik";
import cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { FiTrash } from "react-icons/fi";
import { IoAddSharp, IoChevronDown } from "react-icons/io5";

import api from "services/api";
import pathnames from "routes/pathnames";
import Input from "components/form/input";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import { onHandleRequestError, getPrefixUrl, removeIndexFromArray } from "common/utilities";
import useWindowSize from "hooks/use-window-resize";
import AppLayout from "components/shared/app-layout";
import AppButton from "components/shared/app-button";
import AppPrefixLink from "components/shared/app-prefix-link";
import { updateAlert } from "redux/slices/alert-slice";
import { initialState as subscriptionsInitialState, updatePriceTier, updateSubscriptions, updateDevicesDropdown } from "redux/slices/subscription-slice";

const fieldNames = {
    code: "code",
    imei: "imei",
    device: "device",
};
const country = process.env.REACT_APP_COUNTRY_CODE;
const articleDescriptionKey = "article.description.keyword";
const articleSkuKey = "article.sku.keyword";

const validationSchema = Yup.object().shape({
    redeems: Yup.array().of(
        Yup.object().shape({
            code: Yup.string().required("Code is required"),
            imei: Yup.string().required("IMEI is required"),
            device: Yup.object().required("Device is required"),
        })
    ),
});

const getQueryParam = (field, value) => `&filter_field[]=${field}&filter_value[]=${encodeURIComponent(value)}`;

const PageActivatePlan = () => {
    const isMount = useIsMount();
    const dispatch = useDispatch();
    const priceTier = useRef();
    const history = useHistory();
    const { t } = useTranslation("activatePlan");
    const [windowWidth] = useWindowSize();
    const subscriptions = useSelector((state) => state.subscriptions);
    const [devicesDropdown, setDevicesDropdown] = useState(subscriptions.devicesDropdown);
    const [formikValues, setformikValues] = useState(subscriptions.redeemsSubscriptions);
    const formik = useFormik({
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: formikValues,
        validationSchema,
        onSubmit: (values) => {
            dispatch(updatePriceTier(priceTier.current));
            dispatch(updateDevicesDropdown(devicesDropdown));
            dispatch(updateSubscriptions(values));
            history.push(getPrefixUrl(pathnames.pageActivatePlanReview));
        },
    });
    const disabledSubmitButton = formik.errors.redeems || formik.isSubmitting;
    const inputs = useMemo(
        () => [
            {
                type: "text",
                name: fieldNames.code,
                label: t("create.form.fields.0.label"),
                placeholder: t("create.form.fields.0.label"),
            },
            {
                type: "text",
                name: fieldNames.imei,
                label: t("create.form.fields.1.label"),
                placeholder: t("create.form.fields.1.label"),
            },
            {
                type: "select",
                name: fieldNames.device,
                label: t("create.form.fields.2.label"),
                placeholder: t("create.form.fields.2.label"),
            },
            {
                type: "delete",
                name: "delete",
            },
        ],
        [t]
    );

    useEffect(() => {
        if (isMount) {
            priceTier.current = subscriptions.priceTier;
        }
    }, [isMount, subscriptions]);

    const getElasticSearchParams = ({ field, searchValue, carrierCode, fromPriceRangeAmount, toPriceRangeAmount }) => {
        const params = [];
        const today = moment().format(CONSTANSTS.DATE_FORMAT.YYYYMMDD);
        const activeDevice = getQueryParam("article.active", true);
        const goLiveDate = getQueryParam(`systemConfiguration.goLiveDate.${country}.value.${carrierCode}.to`, today);
        const availableForOpenEnrolement = getQueryParam(`systemConfiguration.availableForDirectToCustomer.${country}.value.${carrierCode}`, true);
        const suggestedRetailPriceFrom = getQueryParam(`suggestedRetailPrice.price.${country}.value.${carrierCode}.amount.from`, fromPriceRangeAmount);
        const suggestedRetailPriceTo = getQueryParam(`suggestedRetailPrice.price.${country}.value.${carrierCode}.amount.to`, toPriceRangeAmount);
        const limit = "&limit=1";
        const deviceKey = "article.description";
        const keyword = `?facets[]=${articleSkuKey}&facets[]=${articleDescriptionKey}`;
        let deviceParams = "";

        switch (field) {
            case fieldNames.code:
                break;
            case fieldNames.device:
                deviceParams = getQueryParam(deviceKey, searchValue);
                break;
            default:
                break;
        }

        params.unshift(keyword);
        params.push(deviceParams);
        params.push(availableForOpenEnrolement);
        params.push(activeDevice);
        params.push(goLiveDate);
        params.push(suggestedRetailPriceFrom);
        params.push(suggestedRetailPriceTo);
        params.push(limit);

        const joinedParams = params.join("");

        return joinedParams;
    };

    const onSearchArticle = async (searchField) => {
        const { index } = searchField;
        const params = getElasticSearchParams(searchField);
        const dropdown = cloneDeep(devicesDropdown);

        try {
            const response = await api.get.searchArticle(params);
            dropdown[index] = response.data.facets[0];
            setDevicesDropdown(dropdown);
        } catch (error) {
            onHandleRequestError(error);
        }
    };

    const onHandleDuplicatedVoucherCode = (voucherCode, index) => {
        const errorMessageKey = { message: t("serverErrors:voucherCode.validateVoucherCode.fullyUsed") };
        const duplicatedVoucherIndex = formik.values.redeems.findIndex((redeem, valuesIndex) => {
            return redeem.code === voucherCode && valuesIndex !== index;
        });

        if (duplicatedVoucherIndex > -1) {
            onResetRedeemForm(index);
            dispatch(updateAlert(errorMessageKey));
        }

        return duplicatedVoucherIndex < 0;
    };

    const onSearchActivationCode = async (voucherCode, searchField) => {
        const { index, searchValue } = searchField;
        const nonduplicatedVoucherCode = onHandleDuplicatedVoucherCode(searchValue, index);

        if (nonduplicatedVoucherCode) {
            try {
                const params = {
                    productCode: "redemption",
                    voucherCode: voucherCode,
                    countryCode: country,
                };
                let enhancedSearchField = searchField;
                const nextPriceTier = cloneDeep(priceTier.current);
                const response = await api.get.priceTier(params);

                if (!nextPriceTier?.[index]) {
                    nextPriceTier[index] = {};
                }

                nextPriceTier[index] = response.data;
                priceTier.current = nextPriceTier;
                enhancedSearchField.carrierCode = nextPriceTier[index].planPricingTier.carrierCode;
                enhancedSearchField.fromPriceRangeAmount = nextPriceTier[index].planPricingTier.fromPriceRange?.amount;
                enhancedSearchField.toPriceRangeAmount = nextPriceTier[index].planPricingTier.toPriceRange?.amount;
                onSearchArticle(enhancedSearchField);
            } catch (error) {
                const callback = () => {
                    onResetRedeemForm(index);
                };
                onHandleRequestError(error, callback);
            }
        }
    };

    const onHandleInputChange = (event, redeemIndex) => {
        const name = event.target.name;
        const value = event.target.value.trim();
        const field = `redeems[${redeemIndex}].${name}`;

        switch (name) {
            case fieldNames.device:
                const deviceValue = JSON.parse(value);
                formik.setFieldValue(field, deviceValue);
                break;
            default:
                formik.setFieldValue(field, value);
                break;
        }

        if (!value && name !== fieldNames.imei) {
            onResetRedeemForm(redeemIndex);
        }
    };

    const onHandleSearchActivateionCode = (event, redeemIndex) => {
        const name = event.target.name;
        const value = event.target.value.trim();
        let enhancedSearchField = { field: name, index: redeemIndex, searchValue: value };

        if (value && name === fieldNames.code) {
            onSearchActivationCode(value, enhancedSearchField);
        }
    };

    const onHandleAddRedeemDevice = () => {
        const deepCloneInitialValues = cloneDeep(subscriptionsInitialState.redeemsSubscriptions.redeems);
        const redeems = formik.values.redeems;
        const joinedValues = { redeems: [...redeems, ...deepCloneInitialValues] };
        dispatch(updateSubscriptions(joinedValues));
        dispatch(updateDevicesDropdown(devicesDropdown));
        dispatch(updatePriceTier(priceTier.current));
        setformikValues(joinedValues);
    };

    const onHandleDeleteInput = (redeemIndex) => {
        const currentRedeemFields = `redeems[${redeemIndex}]`;
        const redeemsMoreThanOne = formik.values.redeems.length > 1;

        if (redeemsMoreThanOne) {
            const redeems = cloneDeep(formik.values.redeems);
            redeems.splice(redeemIndex, 1);

            dispatch(updateSubscriptions({ redeems }));
            setformikValues({ redeems });
        } else {
            const formCleanvalues = cloneDeep(subscriptionsInitialState.redeemsSubscriptions);
            dispatch(updateSubscriptions(formCleanvalues));
            for (var field of Object.keys(fieldNames)) {
                formik.setFieldValue(`${currentRedeemFields}.${field}`, "");
            }
        }

        const dropdown = cloneDeep(devicesDropdown);
        const priceTiers = cloneDeep(priceTier.current);
        const removedDropdown = removeIndexFromArray(dropdown, redeemIndex);
        const removedPriceTiers = removeIndexFromArray(priceTiers, redeemIndex);

        priceTier.current = removedPriceTiers;
        setDevicesDropdown(removedDropdown);
        dispatch(updatePriceTier(removedPriceTiers));
        dispatch(updateDevicesDropdown(removedDropdown));
    };

    const onClearDevicesDropdown = (redeemIndex) => {
        const devicesDropdownCleanvalues = cloneDeep(subscriptionsInitialState.devicesDropdown);
        const dropdown = cloneDeep(devicesDropdown);
        dropdown[redeemIndex] = devicesDropdownCleanvalues[0];
        setDevicesDropdown(dropdown);
    };

    const onResetRedeemForm = (redeemIndex) => {
        const currentRedeemFields = `redeems[${redeemIndex}]`;
        const formCleanvalues = cloneDeep(subscriptionsInitialState.redeemsSubscriptions);
        const redeems = cloneDeep(formik.values.redeems);
        redeems[redeemIndex] = formCleanvalues.redeems[0];

        for (var field of Object.keys(fieldNames)) {
            formik.setFieldValue(`${currentRedeemFields}.${field}`, "");
        }

        setformikValues({ redeems });
        onClearDevicesDropdown(redeemIndex);
        dispatch(updateSubscriptions({ redeems }));
    };

    const onHandleToggleAccordion = (event) => {
        const currentTrigger = event.currentTarget;
        const item = currentTrigger.parentNode;
        item.classList.toggle("is-active");
    };

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-activate-plan">
                <div className="activate-plan">
                    <div className="container">
                        <p className="activate-plan__headline">{t("create.headline")}</p>

                        <div className="activate-plan__labels">
                            {inputs.map((input, index) => {
                                const isDeleteHeader = input.label === "delete";
                                const inputLabelKey = `input-label-${index}`;
                                const headerLabel = !isDeleteHeader ? input.label : "";
                                return (
                                    <p className="activate-plan__text" key={inputLabelKey}>
                                        {headerLabel}
                                    </p>
                                );
                            })}
                        </div>

                        <form className="activate-plan__form" autoComplete="off" onSubmit={formik.handleSubmit}>
                            {formik.values.redeems.map((values, redeemIndex) => {
                                const redeemKey = `activate-plan-device-${redeemIndex}`;
                                const deviceIndex = redeemIndex + 1;

                                return (
                                    <div key={redeemKey} className="activate-plan__inputs-accordion is-active">
                                        <div className="activate-plan__accordion-toggle" onClick={onHandleToggleAccordion}>
                                            {t("create.accordion.label")} {deviceIndex}
                                            <IoChevronDown className="activate-plan__accordion-chevron" />
                                        </div>

                                        <div className="activate-plan__input-wrapper">
                                            {inputs.map((input, inputIndex) => {
                                                const inputKey = `activate-plan-input-${inputIndex}`;
                                                const { type, name } = input;
                                                const inputValue = values[name];
                                                const fieldDropdownAvailable = devicesDropdown[redeemIndex] && devicesDropdown[redeemIndex];
                                                let optionItems = null;

                                                if (fieldDropdownAvailable) {
                                                    const availableDropdown = devicesDropdown[redeemIndex].facetValues;

                                                    if (availableDropdown) {
                                                        optionItems = availableDropdown.map((option, optionIndex) => {
                                                            const label = option.value[articleDescriptionKey];
                                                            const value = JSON.stringify(option.value);
                                                            const optionKey = `activate-plan-option-${optionIndex}-${label}`;
                                                            return (
                                                                <option key={optionKey} value={value}>
                                                                    {label}
                                                                </option>
                                                            );
                                                        });
                                                    }
                                                }

                                                switch (type) {
                                                    case "delete":
                                                        return (
                                                            <div className="activate-plan__input-delete" key={inputKey}>
                                                                <button className="activate-plan__delete-button" type="button" onClick={() => onHandleDeleteInput(redeemIndex)}>
                                                                    <span>{t("create.form.deleteButton")}</span>
                                                                    <FiTrash />
                                                                </button>
                                                            </div>
                                                        );
                                                    case "select":
                                                        const selectedValue = inputValue ? JSON.stringify(inputValue) : "";
                                                        return (
                                                            <div className="activate-plan__input-group activate-plan__select-devices" key={inputKey}>
                                                                <Input {...input} disabled={!optionItems} value={selectedValue} onChange={(event) => onHandleInputChange(event, redeemIndex)}>
                                                                    <option value="" disabled>
                                                                        {windowWidth > 991 ? input.placeholder : ""}
                                                                    </option>
                                                                    {optionItems ? optionItems : null}
                                                                </Input>
                                                            </div>
                                                        );
                                                    default:
                                                        return (
                                                            <div className="activate-plan__input-group" key={inputKey}>
                                                                <Input {...input} value={inputValue} onChange={(event) => onHandleInputChange(event, redeemIndex)} onBlur={(event) => onHandleSearchActivateionCode(event, redeemIndex)} />
                                                            </div>
                                                        );
                                                }
                                            })}
                                        </div>
                                    </div>
                                );
                            })}

                            <div className="add-device-wrapper">
                                <div className="add-device-wrapper__decoration" />
                                <div className="add-device-wrapper__decoration" />
                                <div className="add-device-wrapper__decoration-wrapper">
                                    <div className="add-device-wrapper__decoration" />
                                    <button className="add-device-wrapper__button" type="button" onClick={onHandleAddRedeemDevice} disabled={formik.errors.redeems}>
                                        <span>{t("create.form.addDeviceButton")}</span>
                                        <IoAddSharp />
                                    </button>
                                </div>
                            </div>

                            <AppButton type="submit" label={t("create.form.submitButton")} disabled={disabledSubmitButton} isLoading={formik.isSubmitting} />
                        </form>
                    </div>
                    <div className="container">
                        <p className="eligibility">
                            <Trans
                                i18nKey="activatePlan:create.deviceEligibility"
                                components={{
                                    a: <AppPrefixLink to={pathnames.pageAbout}>Eligible table</AppPrefixLink>,
                                }}
                            />
                        </p>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageActivatePlan;
