import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import pathnames from "routes/pathnames";
import { getPrefixUrl } from "common/utilities";

const withAuthenticate = (WrappedComponent, authenticate) => ({ ...props }) => {
    const auth = localStorage.getItem("@storage_token");
    const location = useLocation();

    if (!auth && authenticate) {
        return (
            <Redirect
                to={{
                    search: "",
                    pathname: getPrefixUrl(pathnames.pageAccountSignIn),
                    state: { referrer: location.pathname },
                }}
            />
        );
    }

    return <WrappedComponent {...props} />;
};
export default withAuthenticate;
