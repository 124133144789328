import React, { memo, useEffect, useCallback } from "react";
import { compose } from "redux";
import { Modal } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import withProfile from "contexts/with-profile";
import Button from "components/shared/app-button";
import { resetModal } from "redux/slices/session-expired-modal-slice";

const AppSessionExpiredModal = (props) => {
    const dispatch = useDispatch();
    const { sessionExpiredModal } = useSelector((state) => state);
    const { t } = useTranslation("sessionExpiredModal");

    const onHandleResetModal = useCallback(() => {
        dispatch(resetModal());
    }, [dispatch]);

    const onHandleDismissModal = () => {
        props.getProfile();
        onHandleResetModal();
    };

    useEffect(() => {
        onHandleResetModal();
    }, [onHandleResetModal]);

    return (
        <Modal className="app-session-expired-modal" z-index={"1051"} backdrop={"static"} isOpen={sessionExpiredModal.active} toggle={onHandleResetModal} centered>
            <div className="app-session-expired-modal__header">
                <p className="app-session-expired-modal__header-text">{t("headline")}</p>
            </div>
            <div className="content">
                <p className="content__text">{t("message")}</p>
            </div>
            <Button label={t("okButton")} type="button" onClick={onHandleDismissModal} />
        </Modal>
    );
};

export default compose(memo, withProfile)(AppSessionExpiredModal);
