import React, { memo, useEffect, useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Modal } from "reactstrap";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import { trimStrings } from "common/utilities";
import Button from "components/shared/app-button";
import Input from "components/form/input";

const isProfileAddress = (value) => value === false;
const AppDeliveryAddressModal = ({ defaultValues, isOpen, toggle, onSubmit }) => {
    const { t } = useTranslation("deliveryAddressModal");
    const { profile } = useSelector((state) => state.auth);
    const isOriginalAddress = defaultValues?.companyName ? true : false;
    const originalAddress = useMemo(
        () => ({
            companyName: profile?.company?.name,
            phoneNumber: profile?.contact?.phoneNumber,
            email: profile?.contact?.email,
            firstName: profile?.contact?.firstName,
            lastName: profile?.contact?.lastName,
            street: profile?.contact?.addresses?.[0]?.street,
            number: profile?.contact?.addresses?.[0]?.number,
            zipCode: profile?.contact?.addresses?.[0]?.zipCode,
            city: profile?.contact?.addresses?.[0]?.city,
            provinceCode: profile?.contact?.addresses?.[0]?.provinceCode,
        }),
        [profile]
    );
    const initialValues = useMemo(
        () => ({
            myAddress: isOriginalAddress,
            ...(isOriginalAddress
                ? {
                      phoneNumber: "",
                      email: "",
                      firstName: "",
                      lastName: "",
                      street: "",
                      number: "",
                      zipCode: "",
                      city: "",
                      provinceCode: "",
                  }
                : {
                      phoneNumber: defaultValues?.phoneNumber,
                      email: defaultValues?.email,
                      firstName: defaultValues?.firstName,
                      lastName: defaultValues?.lastName,
                      street: defaultValues?.street,
                      number: defaultValues?.number,
                      zipCode: defaultValues?.zipCode,
                      city: defaultValues?.city,
                      provinceCode: defaultValues?.provinceCode,
                  }),
        }),
        [isOriginalAddress, defaultValues]
    );
    const inputs = useMemo(
        () => [
            {
                type: "tel",
                name: "phoneNumber",
                label: t("form.fields.0.label"),
            },
            {
                type: "text",
                name: "email",
                label: t("form.fields.1.label"),
            },
            {
                type: "text",
                name: "firstName",
                label: t("form.fields.2.label"),
            },
            {
                type: "text",
                name: "lastName",
                label: t("form.fields.3.label"),
            },
            {
                type: "text",
                name: "street",
                label: t("form.fields.4.label"),
            },
            {
                type: "text",
                name: "number",
                label: t("form.fields.5.label"),
            },
            {
                type: "text",
                name: "zipCode",
                label: t("form.fields.6.label"),
            },
            {
                type: "text",
                name: "city",
                label: t("form.fields.7.label"),
            },
            {
                type: "text",
                name: "provinceCode",
                label: t("form.fields.8.label"),
            },
        ],
        [t]
    );
    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                firstName: Yup.string().when("myAddress", { is: isProfileAddress, then: Yup.string().required(t("form.fields.0.required")) }),
                lastName: Yup.string().when("myAddress", { is: isProfileAddress, then: Yup.string().required(t("form.fields.1.required")) }),
                street: Yup.string().when("myAddress", { is: isProfileAddress, then: Yup.string().required(t("form.fields.2.required")) }),
                number: Yup.number().when("myAddress", { is: isProfileAddress, then: Yup.number().required(t("form.fields.3.required")).typeError(t("form.fields.3.required")) }),
                zipCode: Yup.number().when("myAddress", { is: isProfileAddress, then: Yup.number().required(t("form.fields.4.required")).typeError(t("form.fields.4.required")) }),
                city: Yup.string().when("myAddress", { is: isProfileAddress, then: Yup.string().required(t("form.fields.5.required")) }),
                provinceCode: Yup.string().when("myAddress", { is: isProfileAddress, then: Yup.string().required(t("form.fields.6.required")) }),
            }),
        [t]
    );
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            const { myAddress, ...formValues } = values;
            const form = trimStrings(formValues);

            if (!myAddress) {
                onSubmit(form);
            } else {
                onSubmit(originalAddress);
            }
        },
    });

    useEffect(() => {
        const onHandleClearErrorMessage = () => {
            if (!isOpen && formik.dirty) {
                formik.resetForm(initialValues);
            }
        };

        onHandleClearErrorMessage();
    }, [isOpen, formik, initialValues]);

    const onHandleRadioChange = () => {
        formik.setFieldValue("myAddress", !formik.values.myAddress);
    };

    return (
        <Modal className="app-delivery-address-modal" isOpen={isOpen} toggle={() => toggle(false)} centered>
            <div className="delivery-address-modal">
                <button className="delivery-address-modal__close-button" onClick={() => toggle(false)}>
                    <IoClose size={20} />
                </button>
                <p className="delivery-address-modal__text delivery-address-modal__headline">{t("headline")}</p>

                <form className="delivery-address-modal__form" autoComplete="off" onSubmit={formik.handleSubmit}>
                    <div className="delivery-address-modal__input-group">
                        <div className="delivery-address-modal__content">
                            <div className="delivery-address-modal__radio-wrapper">
                                <Input name="myAddress" type="radio" checked={formik.values.myAddress} onChange={onHandleRadioChange} />
                                <label className="delivery-address-modal__label">{t("form.radios.0.label")}</label>
                            </div>
                        </div>

                        <div className="delivery-address-modal__content">
                            <div className="delivery-address-modal__radio-wrapper">
                                <Input name="customAddress" type="radio" checked={!formik.values.myAddress} onChange={onHandleRadioChange} />
                                <label className="delivery-address-modal__label">{t("form.radios.1.label")}</label>
                            </div>
                        </div>
                    </div>

                    {formik.values.myAddress && (
                        <div className="delivery-address-modal__address-wrapper">
                            <p className="delivery-address-modal__text delivery-address-modal__name">{originalAddress.companyName}</p>
                            <p className="delivery-address-modal__text delivery-address-modal__contact">
                                {originalAddress.phoneNumber}
                                <br /> {originalAddress.email} <br />
                            </p>
                            <p className="delivery-address-modal__text delivery-address-modal__address">
                                {originalAddress.street}, {originalAddress.number}, <br />
                                {originalAddress.zipCode}, {originalAddress.city}, <br />
                                {originalAddress.provinceCode}
                            </p>
                        </div>
                    )}

                    <div className="delivery-address-modal__input-wrapper">
                        {!formik.values.myAddress &&
                            inputs.map((input, index) => {
                                const name = input.name;
                                const touched = formik.touched[name];
                                const error = formik.errors[name];
                                const key = `delivery-address-modal-input-${index}`;

                                return <Input key={key} {...input} revert error={error} touched={touched} value={formik.values[name]} onChange={formik.handleChange} onBlur={formik.handleBlur} />;
                            })}
                    </div>

                    <div className="delivery-address-modal__button-wrapper">
                        <Button label={t("form.submitButton")} type="submit" disabled={formik.isSubmitting} />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default memo(AppDeliveryAddressModal);
