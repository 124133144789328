import React, { memo } from "react";
import { Spinner } from "reactstrap";
import { IoChevronForwardSharp } from "react-icons/io5";

const AppButton = ({ label, showIcon = true, className = "", isLoading, ...props }) => {
    const buttonClassNames = ["app-button", className].join(" ").trim();

    return (
        <button className={buttonClassNames} {...props}>
            {isLoading && <Spinner className="app-button__loading" size="sm" />}
            <span>{label}</span>
            {showIcon && <IoChevronForwardSharp className="app-button__icon" />}
        </button>
    );
};

export default memo(AppButton);
