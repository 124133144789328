import React, { useState } from "react";
import chunk from "lodash/chunk";
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5";
import { getNumbersRange } from "common/utilities";

const AppPagination = ({ currentPage = 1, onHandleClick, data = [], pageLimit = 6, pageNeighbours = 2, totalRecords = 0 }) => {
    const [currentNumber, setCurrentNumber] = useState(currentPage);
    const totalPages = Math.ceil(totalRecords / pageLimit);
    const disabledPrev = currentNumber === 1;
    const disabledNext = currentNumber === totalPages;

    const fetchPageNumbers = () => {
        const totalNumbers = pageNeighbours * 2;
        const totalBlocks = totalNumbers + 1;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(1, currentNumber - pageNeighbours);
            const endPage = Math.min(totalPages, currentNumber + pageNeighbours);
            const minPageLength = pageNeighbours * 2 + 1;
            const endPageLessThanMinPageLength = endPage < minPageLength;
            const isEndPage = endPage === totalPages;

            let pages = getNumbersRange(startPage, endPage);
            const insufficientLength = pages.length < minPageLength;

            if (insufficientLength && endPageLessThanMinPageLength) {
                pages = getNumbersRange(startPage, minPageLength);
            }

            if (insufficientLength && isEndPage) {
                const reversedStartPage = startPage - (minPageLength - pages.length);
                pages = getNumbersRange(reversedStartPage, endPage);
            }

            return pages;
        }

        return getNumbersRange(1, totalPages);
    };

    const onHandleSliceData = (current) => {
        const chunkData = chunk(data, pageLimit);
        return chunkData[current - 1];
    };

    const onHandleGotoPage = (page) => {
        const current = Math.max(1, Math.min(page, totalPages));
        const newData = onHandleSliceData(current);
        setCurrentNumber(current);
        onHandleClick?.(newData);
    };

    const onhandleClickPage = (page) => {
        onHandleGotoPage(page);
    };

    const onhandleMovePrev = () => {
        onHandleGotoPage(currentNumber - 1);
    };

    const onhandleClickMoveNext = () => {
        onHandleGotoPage(currentNumber + 1);
    };

    const pages = fetchPageNumbers();

    return (
        <nav className="app-pagination">
            <ul className="pagination">
                <li className="pagination__item pagination__item--prev">
                    <button className="pagination__button" disabled={disabledPrev} onClick={onhandleMovePrev}>
                        <IoChevronBackSharp />
                    </button>
                </li>
                {pages.map((page, index) => {
                    const active = currentNumber === page;

                    return (
                        <li key={index} className={getPaginationClassNames({ active })}>
                            <button className="pagination__button" onClick={() => onhandleClickPage(page)}>
                                {page}
                            </button>
                        </li>
                    );
                })}
                <li className="pagination__item pagination__item--next" onClick={onhandleClickMoveNext}>
                    <button className="pagination__button" disabled={disabledNext} onClick={onhandleClickMoveNext}>
                        <IoChevronForwardSharp />
                    </button>
                </li>
            </ul>
        </nav>
    );
};

const getPaginationClassNames = ({ active }) => {
    const classNames = ["pagination__item"];

    if (active) classNames.push("pagination__item--active");

    return classNames.join(" ");
};

export default AppPagination;
