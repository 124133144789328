import React, { Fragment, memo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import AppHeader from "components/shared/app-header";
import AppFooter from "components/shared/app-footer";

const AppLayout = ({ hasHeader, hasFooter, children }) => {
    const { i18n } = useTranslation();
    const lang = `${i18n.language}_${process.env.REACT_APP_COUNTRY_CODE}`;

    return (
        <Fragment>
            <Helmet>
                <html lang={lang} />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta http-equiv="cache-control" content="no-cache" />
            </Helmet>

            <div className="app-view">
                {hasHeader && <AppHeader />}

                <main className="app-main">{children}</main>

                {hasFooter && <AppFooter />}
            </div>
        </Fragment>
    );
};

export default memo(AppLayout);
