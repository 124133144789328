import React, { useState, useRef, useMemo, useEffect } from "react";
import * as Yup from "yup";
import { compose } from "redux";
import { useFormik } from "formik";
import { useTranslation, Trans } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import useIsMount from "hooks/use-is-mount";
import withProfile from "contexts/with-profile";
import { onHandleRequestError, sanitizeError, getPrefixUrl, trimStrings } from "common/utilities";
import Input from "components/form/input";
import AppLayout from "components/shared/app-layout";
import AppButton from "components/shared/app-button";
import AppPrefixLink from "components/shared/app-prefix-link";
import AppOtpModal from "components/pages/account/app-otp-modal";

const initialValues = {
    email: "",
    phoneNumber: "",
    idCard: "",
};
const PageAccountSignIn = (props) => {
    const isMount = useIsMount();
    const otpId = useRef("");
    const { t, i18n } = useTranslation("account");
    const history = useHistory();
    const location = useLocation();
    const [otpModalIsOpen, setOtpModalIsOpen] = useState(false);
    const [signInWithEmail, setSignInWithEmail] = useState(false);
    const [isCaptchaEnabled, setIsCaptchaEnabled] = useState(false);
    const validationSchema = useMemo(() => {
        let validations = null;

        if (signInWithEmail) {
            validations = {
                email: Yup.string().required(t("signIn.form.withEmail.fields.0.required")).email(t("signIn.form.withEmail.fields.0.required")),
            };
        } else {
            validations = {
                idCard: Yup.string().required(t("signIn.form.withPhone.fields.0.required")).min(11, t("signIn.form.withPhone.fields.0.minLength")),
                phoneNumber: Yup.number().required(t("signIn.form.withPhone.fields.1.required")).typeError(t("signIn.form.withPhone.fields.1.required")),
            };
        }

        return Yup.object().shape(validations);
    }, [t, signInWithEmail]);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: () => {
            if (signInWithEmail) {
                onHandleSignInWithEmail();
            } else {
                onHandleSignInWithPhone();
            }
        },
    });
    const inputs = useMemo(() => {
        let signInFields = null;

        if (signInWithEmail) {
            signInFields = [
                {
                    type: "email",
                    name: "email",
                    label: t("signIn.form.withEmail.fields.0.label"),
                },
            ];
        } else {
            signInFields = [
                {
                    type: "text",
                    name: "idCard",
                    maxLength: 11,
                    label: t("signIn.form.withPhone.fields.0.label"),
                },
                {
                    type: "tel",
                    name: "phoneNumber",
                    label: t("signIn.form.withPhone.fields.1.label"),
                },
            ];
        }

        return signInFields;
    }, [t, signInWithEmail]);

    const onHandleSignInWithEmail = async (otpCode, callback) => {
        try {
            const form = trimStrings(formik.values);
            let payload = {
                otpId: null,
                otpCode: null,
                email: form.email,
                languageCode: i18n.language,
                countryCode: process.env.REACT_APP_COUNTRY_CODE,
                websiteCode: process.env.REACT_APP_WEBSITE_CODE,
            };

            if (otpCode) {
                payload.otpId = otpId.current;
                payload.otpCode = otpCode;
            } else {
                payload.captchaToken = getCaptchaValidatedKey();
            }

            const response = await api.post.signInWithEmail(payload);
            onHandleSanitizeResponse(response.data);
        } catch (error) {
            if (callback) {
                const sanitizedError = sanitizeError(error);
                callback(sanitizedError.message);
            } else {
                onHandleRequestError(error);
            }
        } finally {
            formik.setSubmitting(false);
            !otpCode && resetCaptcha();
        }
    };

    const onHandleSignInWithPhone = async (otpCode, callback) => {
        try {
            const form = trimStrings(formik.values);
            let payload = {
                otpId: null,
                otpCode: null,
                idCard: form.idCard,
                idCardType: "vatNumber",
                phoneNumber: `${process.env.REACT_APP_PHONE_NUMBER_PREFIX}${form.phoneNumber}`,
                languageCode: i18n.language,
                countryCode: process.env.REACT_APP_COUNTRY_CODE,
                websiteCode: process.env.REACT_APP_WEBSITE_CODE,
            };

            if (otpCode) {
                payload.otpId = otpId.current;
                payload.otpCode = otpCode;
            } else {
                payload.captchaToken = getCaptchaValidatedKey();
            }

            const response = await api.post.signIn(payload);
            onHandleSanitizeResponse(response.data);
        } catch (error) {
            if (callback) {
                const sanitizedError = sanitizeError(error);
                callback(sanitizedError.message);
            } else {
                onHandleRequestError(error);
            }
        } finally {
            formik.setSubmitting(false);
            !otpCode && resetCaptcha();
        }
    };

    const getCaptchaValidatedKey = () => {
        if (isCaptchaEnabled) return window.grecaptcha.getResponse();
    };

    const resetCaptcha = () => {
        if (isCaptchaEnabled) window.grecaptcha.reset();
    };

    const onHandleSanitizeResponse = (response) => {
        const showRequestOtp = response.action === "showOtp";

        if (showRequestOtp) {
            otpId.current = response.otpId;
            onHandleOtpModalVisible(true);
        } else {
            localStorage.setItem("@storage_token", response.token);
            props.getProfile(onHandleRedirectLocation);
        }
    };

    const onHandleRedirectLocation = () => {
        const referrer = location.state?.referrer;
        if (referrer) {
            history.push(referrer);
        } else {
            history.push(getPrefixUrl(pathnames.pageHome));
        }
    };

    const onHandleOtpModalVisible = (status) => {
        setOtpModalIsOpen(status);
    };

    const onHandleOtpModaSubmit = ({ otp }, callback) => {
        if (signInWithEmail) {
            onHandleSignInWithEmail(otp, callback);
        } else {
            onHandleSignInWithPhone(otp, callback);
        }
    };

    const onToggleSignInWithEmail = () => {
        formik.resetForm();
        setSignInWithEmail(!signInWithEmail);
    };

    useEffect(() => {
        if (isMount && typeof window !== 'undefined' && window.grecaptcha) {
            const onHandleGetIsEnabledCaptcha = async () => {
                try {
                    const response = await api.get.captchaEnabled();
                    const isEnableCaptcha = response.data;
                    
                    setIsCaptchaEnabled(isEnableCaptcha);
                    if (isEnableCaptcha) {
                        window.grecaptcha.ready(function () {
                            window.grecaptcha.render('g-recaptcha', {
                            'sitekey' : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
                            'hl': i18n.language,
                            });
                        });
                    }
                } catch (error) {
                    onHandleRequestError(error);
                }
            };
            onHandleGetIsEnabledCaptcha();
        }
    }, [i18n, isMount]);

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-account-sign-in">
                <div className="account-sign-in">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 col-lg-4">
                                <h1 className="account-sign-in__headline">{t("signIn.headline")}</h1>
                                <p className="account-sign-in__sub-headline">{t("signIn.subHeadline")}</p>

                                <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                    {inputs.map((input, index) => {
                                        const name = input.name;
                                        const error = formik.errors[name];
                                        const touched = formik.touched[name];
                                        const key = `create-account-input-${index}`;

                                        return <Input {...input} key={key} error={error} touched={touched} value={formik.values[name]} onChange={formik.handleChange} onBlur={formik.handleBlur} />;
                                    })}

                                    {signInWithEmail && <p className="account-sign-in__text">{t("signIn.withEmailReminder")}</p>}

                                    {isCaptchaEnabled && <div id="g-recaptcha" className="account-sign-in__captcha"></div>}

                                    <button className="account-sign-in__switch" type="button" onClick={onToggleSignInWithEmail}>
                                        {signInWithEmail ? t("signIn.withPhoneButton") : t("signIn.withEmailButton")}
                                    </button>

                                    <div className="account-sign-in__button-wrapper">
                                        <AppButton type="submit" label={t("signIn.form.submitButton")} disabled={formik.isSubmitting} isLoading={formik.isSubmitting} />
                                    </div>
                                </form>

                                <p className="account-sign-in__text">
                                    <Trans i18nKey="account:signIn.createAccount" components={{ a: <AppPrefixLink className="account-sign-in__link" to={pathnames.pageAccountCreate} /> }} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AppOtpModal headline={signInWithEmail ? t("signIn.optModal.withEmail.headline") : null} isOpen={otpModalIsOpen} toggle={onHandleOtpModalVisible} onSubmit={onHandleOtpModaSubmit} />
        </AppLayout>
    );
};

export default compose(withProfile)(PageAccountSignIn);
