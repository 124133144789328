import React, { Fragment, useMemo } from "react";
import * as Yup from "yup";
import { compose } from "redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { IoLockClosedOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";

import api from "services/api";
import Input from "components/form/input";
import withProfile from "contexts/with-profile";
import { onHandleRequestError, trimStrings } from "common/utilities";
import AppButton from "components/shared/app-button";
import AppLayout from "components/shared/app-layout";
import { updateAlert } from "redux/slices/alert-slice";
import AppSideNav from "components/pages/account/app-side-nav";

const PageAccountProfile = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("account");
    const { profile } = useSelector((state) => state.auth);
    const userContact = profile?.contact;
    const userAddress = userContact?.addresses?.[0];
    const inputs = useMemo(
        () => [
            {
                section: t("profile.form.fields.0.section"),
                type: "text",
                name: "firstName",
                label: t("profile.form.fields.0.label"),
                disabled: false,
            },
            {
                type: "text",
                name: "lastName",
                label: t("profile.form.fields.1.label"),
                disabled: false,
            },
            {
                type: "tel",
                name: "phoneNumber",
                label: t("profile.form.fields.2.label"),
                disabled: false,
            },
            {
                type: "email",
                name: "email",
                label: t("profile.form.fields.3.label"),
                disabled: false,
            },
            {
                section: t("profile.form.fields.4.section"),
                type: "text",
                name: "name",
                label: t("profile.form.fields.4.label"),
                disabled: false,
            },
            {
                type: "text",
                name: "vatNumber",
                label: t("profile.form.fields.5.label"),
                icon: <IoLockClosedOutline />,
                disabled: true,
            },
            {
                section: t("profile.form.fields.6.section"),
                type: "text",
                name: "streetName",
                label: t("profile.form.fields.6.label"),
                disabled: false,
            },
            {
                type: "text",
                name: "streetNumber",
                label: t("profile.form.fields.7.label"),
                disabled: false,
            },
            {
                type: "text",
                name: "zipCode",
                label: t("profile.form.fields.8.label"),
                disabled: false,
            },
            {
                type: "text",
                name: "city",
                label: t("profile.form.fields.9.label"),
                disabled: false,
            },
            {
                type: "text",
                name: "provinceCode",
                label: t("profile.form.fields.10.label"),
                disabled: false,
            },
        ],
        [t]
    );
    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                firstName: Yup.string().required(t("profile.form.fields.0.required")),
                lastName: Yup.string().required(t("profile.form.fields.1.required")),
                phoneNumber: Yup.string(t("profile.form.fields.2.required")).required(t("profile.form.fields.2.required")),
                email: Yup.string().email(t("profile.form.fields.3.required")).required(t("profile.form.fields.3.required")),
                name: Yup.string().required(t("profile.form.fields.4.required")),
                vatNumber: Yup.string().required(t("profile.form.fields.5.required")),
                streetName: Yup.string().required(t("profile.form.fields.6.required")),
                streetNumber: Yup.number().required(t("profile.form.fields.7.required")).typeError(t("profile.form.fields.7.required")),
                zipCode: Yup.number().required(t("profile.form.fields.8.required")).typeError(t("profile.form.fields.8.required")),
                city: Yup.string().required(t("profile.form.fields.9.required")),
                provinceCode: Yup.string().required(t("profile.form.fields.10.required")),
            }),
        [t]
    );
    const initialValues = useMemo(
        () => ({
            firstName: userContact?.firstName,
            lastName: userContact?.lastName,
            phoneNumber: userContact?.phoneNumber?.replace(process.env.REACT_APP_PHONE_NUMBER_PREFIX, ""),
            email: userContact?.email,
            name: profile?.company?.name,
            vatNumber: userContact?.ids[0].number,
            streetName: userAddress?.street,
            streetNumber: userAddress?.number,
            zipCode: userAddress?.zipCode,
            city: userAddress?.city,
            provinceCode: userAddress?.provinceCode,
        }),
        [profile, userContact, userAddress]
    );
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: () => {
            onHandleUpdateProfile();
        },
    });

    const onHandleUpdateProfile = async () => {
        try {
            const form = trimStrings(formik.values);
            const payload = {
                company: {
                    name: form.name,
                },
                partnerCodes: profile.partnerCodes,
                contact: {
                    firstName: form.firstName,
                    lastName: form.lastName,
                    dateOfBirth: userContact.dateOfBirth,
                    email: form.email,
                    phoneNumber: `${process.env.REACT_APP_PHONE_NUMBER_PREFIX}${form.phoneNumber}`,
                    addresses: [
                        {
                            id: userAddress.id,
                            countryCode: userAddress.countryCode,
                            type: userAddress.type,
                            building: userAddress.building,
                            city: form.city,
                            district: userAddress.district,
                            flat: userAddress.flat,
                            floor: userAddress.floor,
                            street: form.streetName,
                            zipCode: form.zipCode,
                            number: form.streetNumber,
                            region: userAddress.region,
                            provinceCode: form.provinceCode,
                        },
                    ],
                },
            };
            await api.post.updateProfile(payload);
            dispatch(updateAlert({ message: t("profile.alertModal.success.headline"), success: true }));
            formik.setSubmitting(false);
            props.getProfile();
        } catch (error) {
            onHandleRequestError(error, formik.setSubmitting);
        }
    };

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-account-profile">
                <div className="account-profile">
                    <div className="account-profile__container container">
                        <div className="account-profile__wrapper">
                            <AppSideNav />

                            <form className="account-profile__form col-lg-6" autoComplete="off" onSubmit={formik.handleSubmit}>
                                {inputs.map((input, index) => {
                                    const { section, ...inputProps } = input;
                                    const name = input.name;
                                    const touched = formik.touched[name];
                                    const error = formik.errors[name];
                                    const key = `account-profile-input-${index}`;

                                    return (
                                        <Fragment key={key}>
                                            {section ? <p className="account-profile__category">{section}</p> : null}
                                            <Input {...inputProps} revert error={error} touched={touched} value={formik.values[name]} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        </Fragment>
                                    );
                                })}

                                <div className="account-profile__button-wrapper">
                                    <AppButton type="submit" label={t("profile.form.submitButton")} disabled={formik.isSubmitting} isLoading={formik.isSubmitting} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default compose(withProfile)(PageAccountProfile);
