import React, { memo, useState } from "react";
import { Label, Input as Field } from "reactstrap";

const Input = ({ label, text, touched, error, revert, icon, ...props }) => {
    const [focus, setFocus] = useState(false);
    const invalid = touched && error;
    const isSelect = props.type === "select";
    const selectWithoutLabel = !label && isSelect;
    const labelClassNames = getLabelClassNames({ focus, value: props.value });

    const onHandleFocus = () => {
        setFocus(true);
    };

    const onHandleBlur = (event) => {
        setFocus(false);
        props?.onBlur?.(event);
    };

    return (
        <div className="app-input">
            <div className={getWrapperClassNames({ invalid, revert, selectWithoutLabel })}>
                {isSelect && icon && <div className="app-input__icon app-input__icon--select">{icon}</div>}

                {label && (
                    <Label className={labelClassNames} for={props.name}>
                        {label}
                    </Label>
                )}

                <Field {...props} className="app-input__input" onFocus={onHandleFocus} onBlur={onHandleBlur} />

                {!isSelect && icon && <div className="app-input__icon">{icon}</div>}
            </div>

            {invalid ? <p className="app-input__text app-input__text--error">{error}</p> : null}

            {text && <p className="app-input__text">{text}</p>}
        </div>
    );
};

const getWrapperClassNames = ({ invalid, revert, selectWithoutLabel }) => {
    const classNames = ["app-input__wrapper"];

    if (invalid) classNames.push("app-input__wrapper--error");

    if (revert) classNames.push("app-input__wrapper--revert");

    if (selectWithoutLabel) classNames.push("app-input__wrapper--select-without-label");

    return classNames.join(" ");
};

const getLabelClassNames = ({ focus, value }) => {
    const classNames = ["app-input__label"];

    if (focus || !!value) classNames.push("app-input__label--focus");

    return classNames.join(" ");
};

export default memo(Input);
