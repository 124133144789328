import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { IoChevronForwardSharp } from "react-icons/io5";

import pathnames from "routes/pathnames";
import AppLayout from "components/shared/app-layout";
import AppPrefixLink from "components/shared/app-prefix-link";

const PageHome = () => {
    const { t } = useTranslation("home");

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-home">
                <div className="home">
                    <div className="container">
                        <div className="banner">
                            <div className="banner__content">
                                <h1 className="banner__headline">{t("headline")}</h1>
                                <p className="banner__lead">{t("subHeadline")}</p>
                                <ul className="banner__list">
                                    <Trans i18nKey="home:usp" components={{ li: <li className="banner__list-item" /> }} />
                                </ul>
                            </div>
                        </div>

                        <div className="features">
                            <div className="features__card">
                                <p className="features__header">{t("features.0.title")}</p>
                                <p className="features__text">{t("features.0.description")}</p>
                                <AppPrefixLink className="app-button" to={pathnames.pageActivatePlan}>
                                    <span className="app-button__text">{t("features.0.button")}</span>
                                    <IoChevronForwardSharp className="app-button__icon" />
                                </AppPrefixLink>
                            </div>

                            <div className="features__card">
                                <p className="features__header">{t("features.1.title")}</p>
                                <p className="features__text">{t("features.1.description")}</p>
                                <AppPrefixLink className="app-button" to={pathnames.pageAccountMySwitch}>
                                    <span className="app-button__text">{t("features.1.button")}</span>
                                    <IoChevronForwardSharp className="app-button__icon" />
                                </AppPrefixLink>
                            </div>

                            <div className="features__card">
                                <p className="features__header">{t("features.2.title")}</p>
                                <p className="features__text">{t("features.2.description")}</p>
                                <AppPrefixLink className="app-button" to={pathnames.pageAbout}>
                                    <span className="app-button__text">{t("features.2.button")}</span>
                                    <IoChevronForwardSharp className="app-button__icon" />
                                </AppPrefixLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageHome;
