import React from "react";
import { useDispatch } from "react-redux";

import api from "services/api";
import { onHandleRequestError } from "common/utilities";
import { updateProfile } from "redux/slices/auth-slice";

const withProfile = (WrappedComponent) => ({ ...props }) => {
    const dispatch = useDispatch();

    const getProfile = async (callback) => {
        try {
            const response = await api.get.profile();
            dispatch(updateProfile(response.data));

            if (callback) callback();
        } catch (error) {
            onHandleRequestError(error);
        }
    };

    return <WrappedComponent {...props} getProfile={getProfile} />;
};
export default withProfile;
