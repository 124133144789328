import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    profile: null,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        updateProfile: (state, action) => {
            return {
                ...state,
                profile: action.payload,
            };
        },
        resetProfile: () => initialState,
    },
});

export const { updateProfile, resetProfile } = authSlice.actions;

export default authSlice.reducer;
