import i18n from "i18n";
import moment from "moment";
import store from "redux/store";
import { updateAlert } from "redux/slices/alert-slice";

export const trimStrings = (values) => {
    const isObject = typeof values === "object";
    const initialValue = {};

    if (isObject) {
        const trimmedValues = Object.keys(values).reduce((obj, key) => {
            const isString = typeof values[key] === "string";

            if (isString) {
                obj[key] = values[key].trim();
            } else {
                obj[key] = values[key];
            }
            return obj;
        }, initialValue);

        return trimmedValues;
    }
};

export const openRedirectUrl = (url) => {
    let link = document.createElement("a");
    link.target = "_blank";
    link.href = url;
    link.click();
};

export const getPrefixUrl = (path) => {
    const language = i18n.language;
    return `/${language}${path}`;
};

export const sanitizeError = (originalError) => {
    let error = originalError;

    if (typeof originalError === "object") {
        if (originalError.response) {
            if (originalError.response.data) {
                error = originalError.response.data;
            } else {
                error = originalError.response;
            }
        } else if (originalError.data) {
            error = originalError.data;
        }

        if (error.translationKey) {
            return { message: i18n.t(`serverErrors:${error.translationKey}`) };
        } else {
            return { message: error.message };
        }
    } else {
        error = originalError;
        return { message: error };
    }
};

export const onHandleRequestError = (error, callback) => {
    const isUnauthorised = error.response?.status === 401;
    const dispatch = store.dispatch;
    const sanitizedError = sanitizeError(error);
    dispatch(updateAlert(sanitizedError));

    if (!isUnauthorised && callback) callback(false);
};

export const getBlobToBase64 = (blob) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};

export const removeIndexFromArray = (array, value) => {
    if (array.length === 0) return array;

    if (array.length === 1) return [];

    array.splice(value, 1);

    return array;
};

export const getNumbersRange = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

export const getYearsRange = (total) => {
    const years = [];
    const dateStart = moment();
    const dateEnd = moment().add(total, "y");

    while (dateEnd.diff(dateStart, "years") >= 0) {
        const label = dateStart.format("YYYY");
        const value = dateStart.format("YY");
        const item = { label, value };
        years.push(item);
        dateStart.add(1, "year");
    }

    return years;
};

export const getMonthsRange = (total) => {
    return Array.apply(0, Array(total)).map((_, i) => {
        const label = moment().month(i).format("MMM");
        const value = moment().month(i).format("MM");
        return { label, value };
    });
};
