import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import authReducer from "redux/slices/auth-slice";
import alertReducer from "redux/slices/alert-slice";
import sessionExpiredModalReducer from "redux/slices/session-expired-modal-slice";
import subscriptionsReducer from "redux/slices/subscription-slice";
import fileManagementReducer from "redux/slices/file-management-slice";
import switchesReducer from "redux/slices/switch-slice";
import CONSTANSTS from "common/constansts";

const debug = CONSTANSTS.IS_DEVELOPMENT_MODE;
const persistConfig = { key: "root", storage };
const logger = createLogger({ collapsed: true });
const reducers = {
    auth: authReducer,
    alert: alertReducer,
    subscriptions: subscriptionsReducer,
    switches: switchesReducer,
    fileManagement: fileManagementReducer,
    sessionExpiredModal: sessionExpiredModalReducer,
};
const combinedReducers = combineReducers(reducers);
const middleware = [debug && logger].filter(Boolean);

const rootReducer = (state, action) => {
    if (action.type === "logout") {
        state = undefined;
    }
    return combinedReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({ reducer: persistedReducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middleware) });
export const persistor = persistStore(store);

export default store;
