import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import { getPrefixUrl } from "common/utilities";

const AppPrefixLink = ({ to, ...props }) => {
    const path = getPrefixUrl(to);

    return <NavLink {...props} to={path} />;
};

export default memo(AppPrefixLink);
