import React, { useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { MdEdit } from "react-icons/md";
import cloneDeep from "lodash/cloneDeep";
import { IoCloseOutline } from "react-icons/io5";
import { useTranslation, Trans } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import Checkbox from "components/form/checkbox";
import { getPrefixUrl } from "common/utilities";
import AppLayout from "components/shared/app-layout";
import AppButton from "components/shared/app-button";
import { updateAlert } from "redux/slices/alert-slice";
import { getBlobToBase64, onHandleRequestError } from "common/utilities";
import { resetSubscriptions } from "redux/slices/subscription-slice";
import ProtectedIcon from "assets/images/activate-plan/protected-icon.svg";
import SwitchReplaceImage from "assets/images/activate-plan/switch-and-replace.svg";

const acceptFilesTypes = ".pdf";
const maxFileSize = 10;
const initialValues = {
    files: [],
    terms01: "",
    terms02: "",
};
const articleDescriptionKey = "article.description.keyword";
const articleSkuKey = "article.sku.keyword";

const PageActivatePlanReviewDevice = () => {
    const { fileManagement } = useSelector((state) => state);
    const { redeemsSubscriptions, priceTier } = useSelector((state) => state.subscriptions);
    const invalidSubscriptions = priceTier && Array.isArray(priceTier) && priceTier.length === 0;
    const dispatch = useDispatch();
    const { t } = useTranslation("activatePlan");
    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                files: Yup.array().of(
                    Yup.mixed()
                        .test("fileSize", t("review.invoices.required"), (value) => value?.size / 1024 / 1024 < maxFileSize)
                        .required("Invoice's is required")
                ),
                terms01: Yup.boolean().required(t("review.terms.question.0.required")).oneOf([true], t("review.terms.question.0.required")),
                terms02: Yup.boolean().required(t("review.terms.question.0.required")).oneOf([true], t("review.terms.question.0.required")),
            }),
        [t]
    );
    const history = useHistory();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            onHandleSubmitSubscriptions(values);
        },
    });

    const onHandleGetBase64Files = async (files) => {
        const base64Files = await Promise.all(
            files.map(async (file) => {
                const base64code = await getBlobToBase64(file);
                const mimeType = base64code.split(";")[0].split(":")[1];
                const base64EncryptedData = base64code.split(",")[1];

                return {
                    mimeType,
                    base64EncryptedData,
                    countryCode: process.env.REACT_APP_COUNTRY_CODE,
                    filename: file.name,
                };
            })
        );
        return base64Files;
    };

    const onHandleGetRedeemsArticles = () => {
        const partnerDeviceRedeemDetails = redeemsSubscriptions.redeems.map((redeem, index) => {
            const sku = redeem.device[articleSkuKey];
            const imei = redeem.imei;
            const voucherId = priceTier[index].voucherCode.id;
            return { sku, imei, voucherId };
        });
        return partnerDeviceRedeemDetails;
    };

    const onHandleValidateFiles = (files) => {
        const filesLengthIsValid = Array.isArray(files) && files.length > 0;
        if (filesLengthIsValid) {
            return true;
        } else {
            formik.setSubmitting(false);
            dispatch(updateAlert({ buttonLabel: t("review.alertModal.required.closeButton"), message: t("review.alertModal.required.headline") }));
            return false;
        }
    };

    const onHandleSubmitSubscriptions = async (values) => {
        const isValidFilesLength = onHandleValidateFiles(values.files);

        if (!isValidFilesLength) return;

        try {
            const documents = await onHandleGetBase64Files(values.files);
            const partnerDeviceRedeemDetails = onHandleGetRedeemsArticles();
            const payload = {
                documents,
                partnerDeviceRedeemDetails,
                websiteCode: process.env.REACT_APP_WEBSITE_CODE,
            };
            await api.post.createSubscriptions(payload);
            history.push(getPrefixUrl(pathnames.pageActivatePlanSuccess));
            dispatch(resetSubscriptions());
        } catch (error) {
            onHandleRequestError(error, formik.setSubmitting);
        }
    };

    const onHandleInvoicesAttached = (event) => {
        const files = [...event.target.files];
        formik.setFieldTouched("files", true);
        formik.setFieldValue("files", files);
    };

    const onHandleDeleteInvoices = (index) => {
        const files = cloneDeep(formik.values.files);
        files.splice(index, 1);
        formik.setFieldValue("files", files);
    };

    const onHandleNavigateAddDevices = () => {
        history.push(getPrefixUrl(pathnames.pageActivatePlan));
    };

    if (invalidSubscriptions) return <Redirect to={getPrefixUrl(pathnames.pageActivatePlan)} />;

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-activate-plan-review">
                <div className="activate-plan-review">
                    <div className="container">
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-md-8">
                                    <h1 className="activate-plan-review__headline">{t("review.headline")}</h1>

                                    <ul className="device-list">
                                        {redeemsSubscriptions.redeems.map((redeem, i) => {
                                            const deviceKey = `activate-plan-review-device-${i}`;
                                            const deviceImage = `${process.env.REACT_APP_CDN_IMAGES_ARTICLES_URL}/${redeem.device[articleSkuKey]}-front.png`;
                                            const deviceDescription = redeem.device[articleDescriptionKey];

                                            return (
                                                <li key={deviceKey} className="device-list__item">
                                                    <div className="device-list__image" style={{ backgroundImage: `url(${deviceImage})` }} />
                                                    <p className="activate-plan-review__text">{deviceDescription}</p>
                                                    <button className="device-list__edit-button" onClick={onHandleNavigateAddDevices}>
                                                        <MdEdit />
                                                    </button>
                                                </li>
                                            );
                                        })}
                                    </ul>

                                    <div className="invoices">
                                        <p className="activate-plan-review__text">{t("review.invoices.headline")}</p>
                                        <p className="activate-plan-review__text invoices__text">{t("review.invoices.guideline")}</p>

                                        {formik.values.files?.map?.((file, i) => {
                                            const filesKey = `activate-plan-review-files-${i}`;
                                            return (
                                                <div key={filesKey} className="invoices__attached">
                                                    <p className="activate-plan-review__text">{file.name}</p>
                                                    <button type="button" className="invoices__delete-button" onClick={() => onHandleDeleteInvoices(i)}>
                                                        <IoCloseOutline />
                                                    </button>
                                                </div>
                                            );
                                        })}

                                        <input type="file" id="invoices" hidden multiple accept={acceptFilesTypes} onChange={onHandleInvoicesAttached} />
                                        <label htmlFor="invoices" className="invoices__attach-button">
                                            {t("review.invoices.uploadButton")}
                                        </label>
                                        <p className="activate-plan-review__text invoices__error">{formik.touched.files && formik.errors.files ? formik.errors.files : null}</p>
                                    </div>

                                    <div className="terms">
                                        <p className="activate-plan-review__text terms__headline">{t("review.terms.headline")}</p>

                                        <br />

                                        <p className="activate-plan-review__text terms__desc">{t("review.terms.question.0.title")}</p>

                                        <Checkbox touched={formik.touched.terms01} error={formik.errors.terms01} onChange={(boolean) => formik.setFieldValue("terms01", boolean)} label={t("review.terms.question.0.description")} />

                                        <br />

                                        <p className="activate-plan-review__text terms__desc">{t("review.terms.question.1.title")}</p>

                                        <Checkbox
                                            touched={formik.touched.terms02}
                                            error={formik.errors.terms02}
                                            onChange={(boolean) => formik.setFieldValue("terms02", boolean)}
                                            componentLabel={
                                                <p className="activate-plan-review__text terms__small">
                                                    <Trans
                                                        i18nKey="activatePlan:review.terms.question.1.description"
                                                        components={{
                                                            a: (
                                                                <a href={fileManagement.termsAndConditionsUrl} target="_blank" rel="noopener noreferrer">
                                                                    Terms & Conditions
                                                                </a>
                                                            ),
                                                        }}
                                                    />
                                                </p>
                                            }
                                        />
                                    </div>

                                    <div className="activate-plan-review__button-wrapper">
                                        <AppButton label={t("review.submitButton")} type="submit" disabled={formik.isSubmitting} isLoading={formik.isSubmitting} />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="program">
                                        <div className="row activate-plan-review__row">
                                            <div className="col-md-6 activate-plan-review__col">
                                                <p className="activate-plan-review__text program__title">{t("review.program.headline")}</p>
                                            </div>

                                            <img className="program__image" src={SwitchReplaceImage} alt="" />
                                        </div>

                                        <div className="row activate-plan-review__row">
                                            <div className="col-md-6 activate-plan-review__col">
                                                <p className="activate-plan-review__text program__total">{t("review.program.total")}</p>
                                            </div>

                                            <div className="col-md-6 activate-plan-review__col">
                                                <p className="activate-plan-review__text program__total">{redeemsSubscriptions.redeems.length}</p>

                                                <div className="program__total-devices">
                                                    <img className="program__device-protected-icon" src={ProtectedIcon} alt="" />
                                                    <p className="activate-plan-review__text program__label">{t("review.program.devices")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageActivatePlanReviewDevice;
