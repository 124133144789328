import React from "react";
import { useTranslation, Trans } from "react-i18next";

import AppLayout from "components/shared/app-layout";

const PageCookiePolicy = () => {
    const { t } = useTranslation("cookiePolicy");

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-cookie-policy">
                <div className="cookie-policy">
                    <div className="banner">
                        <div className="container">
                            <div className="banner__content">
                                <h1 className="banner__headline">{t("headline")}</h1>
                                <p className="banner__lead">
                                    <Trans i18nKey="cookiePolicy:lead" />
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="container">
                            <h3>{t("section.title")}</h3>
                            <Trans i18nKey="cookiePolicy:section.intro" />
                            <br />
                            <br />
                            <Trans i18nKey="cookiePolicy:section.whatAreCookies" />
                            <br />
                            <br />
                            <Trans i18nKey="cookiePolicy:section.technicalCookies" />
                            <br />
                            <br />
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ borderBottom: 0 }}>{t("section.technicalCookiesTable.column.0")}</th>
                                        <th style={{ borderBottom: 0 }}>{t("section.technicalCookiesTable.column.1")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{t("section.technicalCookiesTable.row.0.0")}</td>
                                        <td>{t("section.technicalCookiesTable.row.0.1")}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <Trans i18nKey="cookiePolicy:section.otherTechinicalCookies" />
                            <br />
                            <br />
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ borderBottom: 0 }}>{t("section.otherTechinicalCookiesTable.column.0")}</th>
                                        <th style={{ borderBottom: 0 }}>{t("section.otherTechinicalCookiesTable.column.1")}</th>
                                        <th style={{ borderBottom: 0 }}>{t("section.otherTechinicalCookiesTable.column.2")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{t("section.otherTechinicalCookiesTable.row.0.0")}</td>
                                        <td>{t("section.otherTechinicalCookiesTable.row.0.1")}</td>
                                        <td>
                                            <Trans i18nKey="cookiePolicy:section.otherTechinicalCookiesTable.row.0.2" components={{ a: <a href="https://vwo.com/opt-out/" target="_blank" rel="noopener noreferrer">https://vwo.com/opt-out/</a> }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("section.otherTechinicalCookiesTable.row.1.0")}</td>
                                        <td>{t("section.otherTechinicalCookiesTable.row.1.1")}</td>
                                        <td>
                                            <Trans i18nKey="cookiePolicy:section.otherTechinicalCookiesTable.row.1.2" components={{ a: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout</a> }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <br />
                            <Trans i18nKey="cookiePolicy:section.whatAreTacking" />
                            <br />
                            <br />
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ borderBottom: 0 }}>{t("section.whatAreTackingTable.column.0")}</th>
                                        <th style={{ borderBottom: 0 }}>{t("section.whatAreTackingTable.column.1")}</th>
                                        <th style={{ borderBottom: 0 }}>{t("section.whatAreTackingTable.column.2")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{t("section.whatAreTackingTable.row.0.0")}</td>
                                        <td>{t("section.whatAreTackingTable.row.0.1")}</td>
                                        <td>
                                            <Trans
                                                i18nKey="cookiePolicy:section.whatAreTackingTable.row.0.2"
                                                components={{
                                                    1: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout</a>,
                                                    2: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a>,
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <br />
                            <Trans i18nKey="cookiePolicy:section.whatAreTrackingNotes" />
                            <br />
                            <br />
                            <Trans i18nKey="cookiePolicy:section.manageCookies" />
                            <br />
                            <br />
                            <Trans i18nKey="cookiePolicy:section.selectingAppropriateCookies">
                                ...
                                <a href="https://support.microsoft.com/it-it/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy" target="_blank" rel="noopener noreferrer">
                                    https://support.microsoft.com/it-it/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy
                                </a>
                                <a href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647?hl=it</a>
                                <a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie" target="_blank" rel="noopener noreferrer">https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie</a>
                                <a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">https://support.apple.com/it-it/guide/safari/sfri11471/mac</a>
                                <a href="https://www.opera.com/help/tutorials/security/privacy" target="_blank" rel="noopener noreferrer">https://www.opera.com/help/tutorials/security/privacy</a>
                            </Trans>
                            <br />
                            <br />
                            <Trans i18nKey="cookiePolicy:section.knowMore" />
                            <br />
                            <br />
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ borderBottom: 0 }}>{t("section.knowMoreTable.column.0")}</th>
                                        <th style={{ borderBottom: 0 }}>{t("section.knowMoreTable.column.1")}</th>
                                        <th style={{ borderBottom: 0 }}>{t("section.knowMoreTable.column.2")}</th>
                                        <th style={{ borderBottom: 0 }}>{t("section.knowMoreTable.column.3")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{t("section.knowMoreTable.row.0.0")}</td>
                                        <td>{t("section.knowMoreTable.row.0.1")}</td>
                                        <td>
                                            <Trans
                                                i18nKey="cookiePolicy:section.knowMoreTable.row.0.2"
                                                components={{
                                                    1: <a href="https://www.google.com/about/datacenters/inside/locations/index.html" target="_blank" rel="noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/index.html</a>,
                                                    2: <a href="https://privacy.google.com/businesses/subprocessors" target="_blank" rel="noopener noreferrer">https://privacy.google.com/businesses/subprocessors</a>,
                                                }}
                                            />
                                        </td>
                                        <td>{t("section.knowMoreTable.row.0.3")}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <br />
                            <Trans i18nKey="cookiePolicy:section.whatMyRights">
                                ...
                                <a href="mailto:privacy@bolttech.eu">privacy@bolttech.eu</a>
                                <a href="http://www.garanteprivacy.it/cookie" target="_blank" rel="noopener noreferrer">http://www.garanteprivacy.it/cookie</a>
                            </Trans>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageCookiePolicy;
