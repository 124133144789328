import React, { useState, useCallback, useMemo, memo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { IoMenu, IoCloseOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import pathnames from "routes/pathnames";
import { getPrefixUrl } from "common/utilities";
import AppPrefixLink from "components/shared/app-prefix-link";

const getLanguageClassNames = (active) => {
    const classNames = ["lang-switch__lang"];

    if (active) classNames.push("lang-switch__lang--active");

    return classNames.join(" ");
};

const AppHeader = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state.auth);
    const { t, i18n } = useTranslation("header");
    const [mobileNavActive, setMobileNavActive] = useState(false);
    const isSignInPage = location.pathname === getPrefixUrl(pathnames.pageAccountSignIn);
    const initialName = profile ? profile.contact.lastName.split("")[0] + profile.contact.firstName.split("")[0] : "";
    const profileName = profile ? profile.contact.lastName + " " + profile.contact.firstName : "";
    const headerNav = useMemo(
        () => [
            { label: t("activatePlan"), to: pathnames.pageActivatePlan, replace: isSignInPage },
            { label: t("requestASwitch"), to: pathnames.pageAccountMySwitch, replace: isSignInPage },
            { label: t("about"), to: pathnames.pageAbout, replace: false },
            { label: t("signOut"), to: pathnames.pageHome, replace: false, action: "logout" },
        ],
        [isSignInPage, t]
    );

    const getLanguages = useCallback(() => {
        return Object.keys(i18n.services.resourceStore.data);
    }, [i18n]);

    const onToggleMobileNav = useCallback(() => {
        setMobileNavActive((active) => !active);
    }, []);

    const onHandleLogout = useCallback(() => {
        const logoutPathname = getPrefixUrl(pathnames.pageHome);
        const logoutAction = { type: "logout" };
        localStorage.clear();
        dispatch(logoutAction);
        history.push(logoutPathname);
    }, [history, dispatch]);

    const onToggleSwitchLang = useCallback(
        (nextLanguage) => {
            i18n.changeLanguage(nextLanguage).then(() => {
                const languages = getLanguages();
                const currentLanguages = languages.filter((lng) => lng !== nextLanguage)[0];
                if (currentLanguages) {
                    const cleanCurrentPath = location.pathname.split(`/${currentLanguages}`).join("");
                    const prefixUrl = getPrefixUrl(cleanCurrentPath);
                    history.replace(prefixUrl);
                }

                if (window.OneTrust) {
                    window.OneTrust.changeLanguage(nextLanguage);
                }
            });
        },
        [i18n, history, location, getLanguages]
    );

    const getLanguageSwitch = useCallback(() => {
        const languagesOptions = getLanguages();
        return (
            <li className="navigation__item">
                <div className="lang-switch">
                    {languagesOptions?.map((lang, index) => {
                        const langKey = `${lang}-${index}`;
                        const active = i18n.language === lang;
                        return (
                            <button className={getLanguageClassNames(active)} disabled={active} key={langKey} onClick={() => onToggleSwitchLang(lang)}>
                                {lang.toUpperCase()}
                            </button>
                        );
                    })}
                </div>
            </li>
        );
    }, [getLanguages, onToggleSwitchLang, i18n]);

    const getProfileSwitch = useCallback(() => {
        if (profile) {
            return (
                <div className="account">
                    <AppPrefixLink className="account__link" to={pathnames.pageAccountProfile} activeClassName="active">
                        <div className="account__name-initial">{initialName}</div>
                        <p className="account__customer-name">{profileName}</p>
                    </AppPrefixLink>
                </div>
            );
        } else {
            return (
                <div className="sign-in">
                    <AppPrefixLink className="sign-in__link" to={pathnames.pageAccountSignIn} activeClassName="active">
                        {t("signIn")}
                    </AppPrefixLink>
                </div>
            );
        }
    }, [t, profile, initialName, profileName]);

    return (
        <header className="app-header">
            <div className="app-header__container app-header__container--large container">
                <div className="branding">
                    <AppPrefixLink className="branding__link" to={pathnames.pageHome} activeClassName="active">
                        <span className="branding__logo"></span>
                    </AppPrefixLink>
                </div>

                <nav className="navigation navigation--large">
                    <ul className="navigation__list">
                        {headerNav.map((nav, index) => {
                            const navKey = `navigation-item-${index}`;

                            if (nav.action) {
                                if (!profile) return null;

                                return (
                                    <li className="navigation__item" key={navKey} onClick={onHandleLogout}>
                                        <p className="navigation__sign-out">{nav.label}</p>
                                    </li>
                                );
                            }

                            return (
                                <li className="navigation__item" key={navKey}>
                                    <AppPrefixLink className="navigation__link" to={nav.to} activeClassName="active" replace={nav.replace}>
                                        {nav.label}
                                    </AppPrefixLink>
                                </li>
                            );
                        })}
                        {getLanguageSwitch()}
                    </ul>
                </nav>

                {getProfileSwitch()}

                <button className="mobile-nav-toggle" onClick={onToggleMobileNav}>
                    {mobileNavActive ? <IoCloseOutline /> : <IoMenu />}
                </button>
            </div>

            {mobileNavActive && (
                <div className="mobile-nav">
                    <div className="app-header__container app-header__container--small container">
                        <nav className="navigation navigation--small">
                            <ul className="navigation__list">
                                {headerNav.map((nav, index) => {
                                    const navKey = `navigation-item-${index}`;

                                    if (nav.action) {
                                        if (!profile) return null;

                                        return (
                                            <li className="navigation__item" key={navKey} onClick={onHandleLogout}>
                                                <p className="navigation__sign-out">{nav.label}</p>
                                            </li>
                                        );
                                    }

                                    return (
                                        <li className="navigation__item" key={navKey}>
                                            <AppPrefixLink className="navigation__link" to={nav.to} activeClassName="active" replace={nav.replace}>
                                                {nav.label}
                                            </AppPrefixLink>
                                        </li>
                                    );
                                })}
                                {getLanguageSwitch()}
                            </ul>
                        </nav>
                    </div>
                </div>
            )}
        </header>
    );
};

export default memo(AppHeader);
