import React, { useState } from "react";
import { Input } from "reactstrap";
import { FiTrash } from "react-icons/fi";
import cloneDeep from "lodash/cloneDeep";
import { useTranslation } from "react-i18next";
import { IoSearchOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import { onHandleRequestError, getPrefixUrl } from "common/utilities";
import AppLayout from "components/shared/app-layout";
import AppButton from "components/shared/app-button";
import AppSideNav from "components/pages/account/app-side-nav";
import { updateSummary, updateDevices } from "redux/slices/switch-slice";
import arrowIcon from "assets/images/switch/arrow.svg";

const PageSwitchSelectDevice = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation("switches");
    const { profile } = useSelector((state) => state.auth);
    const { devices } = useSelector((state) => state.switches);
    const [switchesDevice, setSwitchesDevice] = useState(devices);
    const [fetchingSwitchSummary, setFetchingSwitchSummary] = useState(false);
    const invalidSelectedSwitchDevices = switchesDevice.some((item) => !item.selectedSwitchDevice);
    const disabledSwitchButton = !switchesDevice.length || invalidSelectedSwitchDevices || fetchingSwitchSummary;
    const invalidDevices = devices.length === 0;

    const onHandleFilterSubscription = (event) => {
        const value = event.target.value.toLowerCase().trim();
        const searchSubscriptions = devices.filter((sub) => {
            const imei = sub.imei.includes(value);
            const deviceDescription = sub.name.toLowerCase().includes(value);
            return imei || deviceDescription;
        });
        setSwitchesDevice(searchSubscriptions);
    };

    const onHandleDeleteDevice = (device) => {
        const newDeviceList = devices.filter((sub) => sub.subscriptionId !== device.subscriptionId);
        setSwitchesDevice(newDeviceList);
        dispatch(updateDevices(newDeviceList));
    };

    const onHandleSelectDevices = (index) => (event) => {
        const value = event.currentTarget.value;
        const newSwitchesDevices = cloneDeep(switchesDevice);
        newSwitchesDevices[index].selectedSwitchDevice = JSON.parse(value);
        setSwitchesDevice(newSwitchesDevices);
    };

    const onHandleUpdateDeliveryDetails = async () => {
        const switchContact = {
            companyName: profile?.company?.name,
            phoneNumber: profile?.contact?.phoneNumber,
            email: profile?.contact?.email,
            firstName: profile?.contact?.firstName,
            lastName: profile?.contact?.lastName,
            street: profile?.contact?.addresses?.[0]?.street,
            number: profile?.contact?.addresses?.[0]?.number,
            zipCode: profile?.contact?.addresses?.[0]?.zipCode,
            city: profile?.contact?.addresses?.[0]?.city,
            provinceCode: profile?.contact?.addresses?.[0]?.provinceCode,
        };
        const updatedSwitchesDeviceContact = switchesDevice.map((device) => Object.assign({}, device, { switchContact }));
        dispatch(updateDevices(updatedSwitchesDeviceContact));

        try {
            setFetchingSwitchSummary(true);
            const payload = switchesDevice.map((device) => {
                const deviceSku = device.deviceSku;
                const subscriptionId = device.subscriptionId;
                return { deviceSku, subscriptionId };
            });
            const response = await api.post.switchSummary(payload);
            dispatch(updateSummary(response.data));
            history.push(getPrefixUrl(pathnames.pageSwitchDeliveryDetails));
        } catch (error) {
            onHandleRequestError(error, setFetchingSwitchSummary);
        }
    };

    if (invalidDevices) return <Redirect to={getPrefixUrl(pathnames.pageAccountMySwitch)} />;

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-switch-select-device">
                <div className="switch-select-device">
                    <div className="switch-select-device__container container">
                        <div className="switch-select-device__wrapper">
                            <AppSideNav />

                            <div className="switches">
                                <div className="switches__header">
                                    <h1 className="switches__headline">{t("selectDevice.headline")}</h1>
                                    <div className="switches__switch-button">
                                        <AppButton label={t("selectDevice.switchButton", { total: devices.length })} disabled={disabledSwitchButton} isLoading={fetchingSwitchSummary} onClick={onHandleUpdateDeliveryDetails} />
                                    </div>
                                </div>

                                <div className="switches__search-wrapper">
                                    <input placeholder={t("selectDevice.searchBar.placeholder")} onChange={onHandleFilterSubscription} />
                                    <IoSearchOutline />
                                </div>

                                <ul className="switches__device-list">
                                    {switchesDevice.map((device, index) => {
                                        const key = `switch_${device.subscriptionId}_${index}`;
                                        const switchDevice = device.selectedSwitchDevice;
                                        const selected = JSON.stringify(switchDevice) || "";
                                        const switchFee = switchDevice?.usageFee?.amount || 0;
                                        const selectedDeviceImage = `${process.env.REACT_APP_CDN_IMAGES_ARTICLES_URL}/${switchDevice?.deviceSku}-front.png`;
                                        const selectedDeviceImageStyle = switchDevice?.deviceSku && { backgroundImage: `url(${selectedDeviceImage})` };
                                        const deviceImage = `${process.env.REACT_APP_CDN_IMAGES_ARTICLES_URL}/${device.sku}-front.png`;
                                        return (
                                            <li key={key} className="switches__device">
                                                <div className="switches__row">
                                                    <div className="switches__col switches__col--info">
                                                        <div className="switches__image" style={{ backgroundImage: `url(${deviceImage})` }} />
                                                        <div className="switches__info">
                                                            <p className="switch-select-device__text switches__name">{device.name}</p>
                                                            <p className="switch-select-device__text switches__imei">IMEI {device.imei}</p>
                                                        </div>
                                                    </div>
                                                    <div className="switches__col">
                                                        <div className="switches__arrow-icon">
                                                            <img src={arrowIcon} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="switches__col switches__col--selection">
                                                        <div className="switches__default-image" style={selectedDeviceImageStyle} />
                                                        <Input className="switches__dropdown" type="select" value={selected} onChange={onHandleSelectDevices(index)}>
                                                            {/* prettier-ignore */}
                                                            <option value="" hidden disabled>{t("selectDevice.field.select.defaultOption")}</option>

                                                            {Object.keys(device?.deviceSelections).map((type, i) => {
                                                                const optionItems = [];
                                                                const devicePlan = CONSTANSTS.DEVICE_PLANS[type];

                                                                device?.deviceSelections[type].forEach((select, selectIndex) => {
                                                                    /* prettier-ignore */
                                                                    const newOption = <option key={selectIndex} value={JSON.stringify(select)}>{select.deviceDescription}</option>;
                                                                    optionItems.push(newOption);
                                                                });

                                                                /* prettier-ignore */
                                                                return <optgroup key={i} label={devicePlan}>{optionItems}</optgroup>;
                                                            })}
                                                        </Input>
                                                    </div>
                                                </div>
                                                <div className="switches__row">
                                                    <div className="switches__col">
                                                        <div className="switches__fee-info">
                                                            <p className="switch-select-device__text switches__label">{t("selectDevice.switchFee.label")}</p>
                                                            <p className="switch-select-device__text switches__fee">{switchFee}€</p>
                                                        </div>
                                                    </div>
                                                    <div className="switches__col">
                                                        <button className="switches__delete-button" type="button" onClick={() => onHandleDeleteDevice(device)}>
                                                            <FiTrash />
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>

                                <div className="switches__footer">
                                    <div className="switches__switch-button">
                                        <AppButton label={t("selectDevice.switchButton", { total: devices.length })} disabled={disabledSwitchButton} isLoading={fetchingSwitchSummary} onClick={onHandleUpdateDeliveryDetails} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageSwitchSelectDevice;
