import { useLayoutEffect, useState } from "react";

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState([0, 0]);

    useLayoutEffect(() => {
        const updateWindowSize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener("resize", updateWindowSize);

        updateWindowSize();

        return () => window.removeEventListener("resize", updateWindowSize);
    }, []);

    return windowSize;
};

export default useWindowSize;
