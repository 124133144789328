import React, { useState, useEffect, useMemo, useRef } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import moment from "moment";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import Checkbox from "components/form/checkbox";
import AppLayout from "components/shared/app-layout";
import AppButton from "components/shared/app-button";
import AppSideNav from "components/pages/account/app-side-nav";
import { onHandleRequestError, getPrefixUrl } from "common/utilities";
import { updateDevices, resetSwitches } from "redux/slices/switch-slice";
import expiresIcon from "assets/images/account-my-switch/expires-icon.svg";

const getSubscriptionStatusClassNames = (status) => {
    const className = ["account-my-switch__text", "subscriptions__device-status"];

    switch (status) {
        case "active":
            className.push("subscriptions__device-status--active");
            break;
        case "inactive":
            className.push("subscriptions__device-status--submitted");
            break;
        case "cancelled":
            className.push("subscriptions__device-status--cancelled");
            break;
        default:
            break;
    }

    return className.join(" ");
};

const getSubscriptionDeviceClassNames = (selected) => {
    const className = ["subscriptions__device"];

    if (selected) {
        className.push("subscriptions__device--selected");
    }

    return className.join(" ");
};

const getSubscriptionStatus = (status) => {
    let text = "Subscription ";

    switch (status) {
        case "active":
            text = text.concat("active");
            break;
        case "inactive":
            text = text.concat("inactive");
            break;
        case "canceled":
            text = text.concat("cancelled");
            break;
        default:
            break;
    }

    return text;
};

const getLatestSwap = (swaps) => {
    let swap = null;

    if (!swaps.length) return swap;

    swap = swaps[swaps.length - 1];

    return swap;
};

const PageAccountMySwitch = () => {
    const dispatch = useDispatch();
    const isMount = useIsMount();
    const history = useHistory();
    const { t } = useTranslation(["account", "common"]);
    const originalSubscriptionLists = useRef();
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [fetchingSubscription, setFetchingSubscription] = useState(false);
    const [fetchingSwitchDevices, setFetchingSwitchDevices] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const switchButtonLabel = useMemo(() => (selectedDevices.length > 0 ? t("subscriptions.switchButton", { total: selectedDevices.length }) : t("subscriptions.defaultswitchButton")), [t, selectedDevices]);
    const disabledSwitchButton = !selectedDevices.length || fetchingSwitchDevices;

    const onHandleFilterSubscription = (event) => {
        const value = event.target.value.toLowerCase().trim();
        const searchSubscriptions = originalSubscriptionLists.current.filter((sub) => {
            const imei = sub.subscription.device.imei.includes(value);
            const deviceDescription = sub.article.description.toLowerCase().includes(value);
            return imei || deviceDescription;
        });

        setSubscriptions(searchSubscriptions);
    };

    useEffect(() => {
        if (isMount) {
            const removeLoading = () => {
                setFetchingSubscription(false);
            };

            const onHandleGetSubscriptions = async () => {
                setFetchingSubscription(true);
                try {
                    const response = await api.get.redeemSubscriptions();
                    const data = response.data;
                    originalSubscriptionLists.current = data;
                    setSubscriptions(data);
                    removeLoading();
                } catch (error) {
                    onHandleRequestError(error, removeLoading);
                }
            };

            dispatch(resetSwitches());
            onHandleGetSubscriptions();
        }
    }, [isMount, dispatch]);

    const onHandleRequestSwitch = async () => {
        setFetchingSwitchDevices(true);
        try {
            const payload = selectedDevices.map((device) => device.subscription.id);
            const response = await api.post.deviceSelection(payload);
            dispatch(updateDevices(response.data));
            history.push(getPrefixUrl(pathnames.pageSwitchSelectDevice));
        } catch (error) {
            onHandleRequestError(error, setFetchingSwitchDevices);
        }
    };

    const onHandleSelectDevice = (newDevice) => {
        const deviceIndex = selectedDevices.findIndex((device) => device.subscription.id === newDevice.subscription.id);

        if (deviceIndex > -1) {
            const newDeviceList = selectedDevices.filter((device) => device.subscription.id !== newDevice.subscription.id);
            setSelectedDevices(newDeviceList);
        } else {
            setSelectedDevices((devices) => [...devices, newDevice]);
        }
    };

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-account-my-switch">
                <div className="account-my-switch">
                    <div className="account-my-switch__container container">
                        <div className="account-my-switch__wrapper">
                            <AppSideNav />
                            <div className="subscriptions">
                                <div className="subscriptions__header">
                                    <h1 className="subscriptions__headline">{t("subscriptions.headline")}</h1>
                                    <div className="subscriptions__switch-button">
                                        <AppButton label={switchButtonLabel} onClick={onHandleRequestSwitch} disabled={disabledSwitchButton} isLoading={fetchingSwitchDevices} />
                                    </div>
                                </div>

                                <div className="subscriptions__search-wrapper">
                                    <input placeholder={t("subscriptions.searchBar.placeholder")} onChange={onHandleFilterSubscription} />
                                    <IoSearchOutline />
                                </div>

                                <ul className="subscriptions__wrapper">
                                    {fetchingSubscription && (
                                        <div className="subscriptions__loading">
                                            <Spinner className="app-spinner" />
                                        </div>
                                    )}

                                    {subscriptions.map((sub, index) => {
                                        const subscription = sub.subscription;
                                        const subscriptionStatus = subscription.status;
                                        const subscriptionKey = `subscription-${subscription.id}-${index}`;
                                        const swap = getLatestSwap(sub.swaps);
                                        const swapDate = moment(swap?.createdAt).format(CONSTANSTS.DATE_FORMAT.DDMMYYYYY_HHMMA);
                                        const statusClassNames = getSubscriptionStatusClassNames(subscriptionStatus);
                                        const statusText = getSubscriptionStatus(subscriptionStatus);
                                        const activeSubscription = subscriptionStatus === "active";
                                        const expiresDate = moment(subscription.activationDate)?.add(sub?.plan?.durationInMonths, "months").format(CONSTANSTS.DATE_FORMAT.DDMMYYYYY);
                                        const deviceImage = `${process.env.REACT_APP_CDN_IMAGES_ARTICLES_URL}/${subscription.device.sku}-front.png`;
                                        const deviceSelected = selectedDevices.findIndex((device) => device.subscription.id === subscription.id) > -1;

                                        return (
                                            <li className={getSubscriptionDeviceClassNames(deviceSelected)} key={subscriptionKey}>
                                                <Checkbox onChange={() => onHandleSelectDevice(sub)} disabled={!activeSubscription || fetchingSwitchDevices} />

                                                <div className="subscriptions__device-content">
                                                    <div className="subscriptions__device-image" style={{ backgroundImage: `url(${deviceImage})` }} />

                                                    <div className="subscriptions__device-info">
                                                        <div className="subscriptions__device-header">
                                                            <p className={statusClassNames}>{statusText}</p>
                                                            <img className="subscriptions__expires-icon" src={expiresIcon} alt="" />
                                                            <p className="account-my-switch__text subscriptions__date">Expires {expiresDate}</p>
                                                        </div>
                                                        {swap && (
                                                            <p className="account-my-switch__text subscriptions__swap-status">
                                                                {t("subscriptions.switchStatus", { status: t(`swap.status.${swap.status}`, { ns: "common" }) })}
                                                                <span className="subscriptions__date"> - {swapDate}</span>
                                                            </p>
                                                        )}
                                                        <p className="account-my-switch__text subscriptions__device-name">{sub.article.description}</p>
                                                        <p className="account-my-switch__text subscriptions__device-imei">IMEI {subscription.device.imei}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>

                                <div className="subscriptions__footer">
                                    <div className="subscriptions__switch-button">
                                        <AppButton label={switchButtonLabel} onClick={onHandleRequestSwitch} disabled={disabledSwitchButton} isLoading={fetchingSwitchDevices} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageAccountMySwitch;
