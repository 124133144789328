import React from "react";
import { useTranslation, Trans } from "react-i18next";

import pathnames from "routes/pathnames";
import AppLayout from "components/shared/app-layout";
import AppPrefixLink from "components/shared/app-prefix-link";

const PageActivatePlanSuccess = () => {
    const { t } = useTranslation("activatePlan");

    return (
        <AppLayout hasHeader hasFooter>
            <div className="app-page page-activate-plan-success">
                <div className="activate-plan-success">
                    <div className="container">
                        <div className="banner">
                            <h1 className="banner__headline">{t("success.headline")}</h1>
                            <p className="banner__sub-headline">
                                <Trans i18nKey="activatePlan:success.button" components={{ a: <AppPrefixLink className="banner__link" to={pathnames.pageAccountMySwitch} /> }} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageActivatePlanSuccess;
